import React, { useEffect, useState } from "react"
import axios from "axios"

import { defaultCountries, parseCountry } from "react-international-phone"
import "react-international-phone/style.css"
import {
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

import PlusIcon from "./Icons/PlusIcon"

import { useSelector } from "react-redux"
import FormUploadDragDrop from "./FormUploadDragDrop"

import CustomSpinner from "components/Common/CustomSpinner"
import ErrorModel from "./ErrorModal"
import EnterPhoneDataComponent from "./EnterPhoneDataComponent"

import configs from "../../../src/config"
const { Url, apiVersion } = configs.client

import "./EnterDataSingleTemplatePreviewComponent.css"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function EnterDataSingleTemplatePreviewComponent({
  step,
  setStep,
  singleTemplateData,
  setSingleTemplateData,
  data,
  setData,
}) {
  let headerVar
  let bodyVars

  const dispatch = useDispatch()

  const handleSubmit = e => {
    e.preventDefault()
    const formElement = document.getElementById("formEnterPhoneNumbers")

    if (formElement) {
      formElement.click()
    }
  }

  let formData = new FormData()

  const { access, authorized } = useSelector(state => ({
    access: state.Login?.userAccess,
    authorized: state.Login.authorized,
  }))

  useEffect(() => {}, [data])

  const [countryCode, setCountryCode] = useState("")
  const countryOptions = defaultCountries.filter(country => {
    const { iso2 } = parseCountry(country)

    return ["eg", "sa", "ae"].includes(iso2)
  })
  const [selectedFile, setSelectedFile] = useState(null)
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [selectedOption, setSelectedOption] = useState("")
  const [singleFileName, setSingleFileName] = useState("")
  const [loaderIndicator, setLoaderIndicator] = useState(false)
  const [fileForEachLoading, setFileForEachLoading] = useState(false)
  const [tempObject, setTempObject] = useState({})

  const [sendingDataLoader, setSendingDataLoader] = useState(false)

  const [selectedOptionText, setSelectedOptionText] = useState(
    "Select a file option"
  )
  const [errorHandler, setErrorHandler] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [formValuesArr, setFormValuesArr] = useState([])

  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const handleConfirm = () => {
    // setIsModalOpen(false)
    // handleSubmit(e)
    toggleModal()
    handleSendingAllData(formValuesArr)
  }

  const handleOptionChange = event => {
    setSelectedOption(event.target.value)
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]

      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handleSendingAllData = clientsArr => {
    setSendingDataLoader(true)

    const updatedClientsArr = singleFileName
      ? clientsArr.map(client => ({ ...client, fileName: singleFileName }))
      : clientsArr

    const dataToSend = { ...data, clients: updatedClientsArr }

    axios
      .post(`${Url}/${apiVersion}/broadcast`, dataToSend, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json; charset=utf8",
        },
      })
      .then(res => {
        setStep(3)
        setSendingDataLoader(false)

        toast.success("Data sent successfully!")
      })
      .catch(err => {
        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          const errorMessage =
            err.response?.data?.message || "Failed to send data."
          toast.error(errorMessage)
        }

        setErrorHandler(true)
        setSendingDataLoader(false)
      })
  }

  const handleSendingEachFile = clientIdx => {
    formData.append("file", selectedFile)
    setFileForEachLoading(true)

    axios
      .post(`${Url}/${apiVersion}/upload/single`, formData, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        setData({
          ...data,
          clients: data.clients.map((client, index) => {
            if (index === clientIdx) {
              return {
                ...client,
                fileName: res.data.file,
              }
            }
            return client
          }),
        })

        setFileForEachLoading(false)
        toast.success("File uploaded successfully!")
      })
      .catch(err => {
        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          const errorMessage =
            err.response?.data?.message || "Failed to upload file."
          toast.error(errorMessage)
        }

        setFileForEachLoading(false)
      })
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      <ToastContainer />

      <div className="templates-list-layout position-relative">
        {errorHandler && (
          <ErrorModel
            errorHandler={errorHandler}
            setErrorHandler={setErrorHandler}
          />
        )}

        {loaderIndicator ||
          (sendingDataLoader && (
            <div className="sending-template-overlay-loading d-flex justify-content-center align-items-center">
              <CustomSpinner />
            </div>
          ))}

        <>
          <h3>Preview Template</h3>
          <div className="template-preview-wrapper template-preview-wrapper-scrollbar">
            <div className="mb-3 template-preview-container">
              <div
                className="text-container"
                style={{
                  textAlign:
                    singleTemplateData?.language == "ar" ? "right" : "left",
                  direction:
                    singleTemplateData?.language == "ar" ? "rtl" : "ltr",
                }}
              >
                <div className="pb-1 font-weight-bold">
                  <h4>
                    <span className="text-dark">
                      {singleTemplateData.name
                        .replace(/_/g, " ")
                        .replace(/\w\S*/g, function (txt) {
                          return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                          )
                        })}
                    </span>
                  </h4>
                </div>

                {singleTemplateData.components.map((component, idx) => {
                  if (component.example && component.example.header_text) {
                    headerVar = component.example.header_text[0]
                  }

                  let modifiedText = component.text

                  if (component.text?.includes("{{1}}")) {
                    if (headerVar && tempObject[headerVar]) {
                      modifiedText = component.text.replace(
                        "{{1}}",
                        `<strong>${tempObject[headerVar]}</strong>`
                      )
                    } else {
                      modifiedText = component.text.replace(
                        "{{1}}",
                        "<strong>{{1}}</strong>"
                      )
                    }
                  }

                  if (component.type == "HEADER")
                    return (
                      <div className="pb-0" key={component.text}>
                        <h5>
                          <span
                            className="text-dark"
                            dangerouslySetInnerHTML={{ __html: modifiedText }}
                          />
                        </h5>
                      </div>
                    )
                })}

                <p className="mb-2">
                  {singleTemplateData.components.map(component => {
                    if (component.example && component.example.body_text) {
                      bodyVars = component.example.body_text[0]
                    }

                    if (component.type === "BODY") {
                      let transformedText

                      transformedText = component.text

                      if (
                        singleTemplateData.components[0]?.example
                          ?.header_text &&
                        singleTemplateData.components[0]?.example
                          ?.header_text[0]
                      ) {
                        let counter = 2
                        transformedText = transformedText?.replace(
                          /{{.*?}}/g,
                          () => {
                            return `<strong>{{${counter++}}}</strong>`
                          }
                        )
                      }

                      bodyVars?.forEach((bodyVar, index) => {
                        const value = tempObject[bodyVar]

                        if (value) {
                          if (headerVar) {
                            transformedText = transformedText.replace(
                              `{{${index + 2}}}`,
                              `<strong>${value}</strong>`
                            )
                          } else {
                            transformedText = transformedText.replace(
                              `{{${index + 1}}}`,
                              `<strong>${value}</strong>`
                            )
                          }
                        } else {
                          if (headerVar) {
                            transformedText = transformedText.replace(
                              `{{${index + 2}}}`,
                              `<strong>{{${index + 2}}}</strong>`
                            )
                          } else {
                            transformedText = transformedText?.replace(
                              `{{${index + 1}}}`,
                              `<strong>{{${index + 1}}}</strong>`
                            )
                          }
                        }
                      })

                      return (
                        <span
                          style={{ whiteSpace: "pre-wrap" }}
                          key={component.text}
                          dangerouslySetInnerHTML={{
                            __html: transformedText,
                          }}
                        />
                      )
                    }
                  })}
                </p>

                <p className="text-dark mb-1">
                  {singleTemplateData.components.map((component, idx) => {
                    if (component.type == "FOOTER")
                      return <span key={idx}>{component.text}</span>
                  })}
                </p>

                {singleTemplateData.components.map((component, idx) => {
                  if (component.type == "BUTTONS")
                    return component.buttons.map((button, idx) => {
                      return (
                        <button
                          className="btn btn-outline-primary d-block mb-1"
                          key={idx}
                        >
                          {button.text}
                        </button>
                      )
                    })
                })}
              </div>
            </div>

            <div className="d-flex mb-2 justify-content-between align-items-center">
              <h4 className="mb-0">Enter Phone Number</h4>

              {singleTemplateData.components[0].format !== "DOCUMENT" &&
                singleTemplateData.components[0].format !== "IMAGE"}

              {(activeTab === 2 ||
                (singleTemplateData.components[0].format !== "DOCUMENT" &&
                  singleTemplateData.components[0].format !== "IMAGE")) && (
                <button
                  className="add-fields-btn"
                  onClick={() => {
                    setData({
                      ...data,
                      clients: [
                        ...data.clients,
                        singleFileName
                          ? { fileName: singleFileName }
                          : tempObject,
                      ],
                    })

                    document.getElementById("insert-form-array-element").click()
                  }}
                >
                  <PlusIcon />
                </button>
              )}
            </div>

            {singleTemplateData.components[0].format === "DOCUMENT" ||
            singleTemplateData.components[0].format === "IMAGE" ? (
              <div className="fields-container mb-2">
                <div className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}
                      >
                        <NavLink
                          className={classnames({ current: activeTab === 1 })}
                          onClick={() => {
                            setactiveTab(1)
                          }}
                          disabled={!(passedSteps || []).includes(1)}
                        >
                          <span className="number">1.</span> Select a file
                          option
                        </NavLink>
                      </NavItem>

                      <NavItem
                        className={classnames({ current: activeTab === 2 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                          onClick={() => {
                            setactiveTab(2)
                          }}
                          disabled={!(passedSteps || []).includes(2)}
                        >
                          <span className="number">2.</span> Enter phone numbers
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>

                  <div className="content clearfix">
                    <TabContent activeTab={activeTab} className="body">
                      <TabPane tabId={1}>
                        <Row>
                          <div className="col-md-12">
                            <UncontrolledDropdown className="w-100">
                              <DropdownToggle
                                className="btn btn-secondary w-100 text-start d-flex justify-content-between btn-md"
                                type="button"
                                id="defaultDropdown"
                              >
                                <span className="font-size-16">
                                  {selectedOptionText}
                                </span>
                                <i className="mdi mdi-chevron-down"></i>
                              </DropdownToggle>
                              <DropdownMenu className="w-100">
                                <DropdownItem
                                  onClick={() => {
                                    setSelectedFile(null)
                                    setSingleFileName("")
                                    setSelectedOption("fileForAll")
                                    setSelectedOptionText(
                                      "Upload a file for all numbers"
                                    )
                                  }}
                                  className="font-size-16"
                                >
                                  Upload a file for all numbers
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setSelectedFile(null)
                                    setSingleFileName("")
                                    setSelectedOption("fileForEach")
                                    setSelectedOptionText(
                                      "Upload a file for each number"
                                    )
                                  }}
                                  className="font-size-16"
                                >
                                  Upload a file for each number
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>

                            {selectedOption === "fileForAll" && (
                              <FormUploadDragDrop
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                                singleTemplateData={singleTemplateData}
                                access={access}
                                singleFileName={singleFileName}
                                setSingleFileName={setSingleFileName}
                                loaderIndicator={loaderIndicator}
                                setLoaderIndicator={setLoaderIndicator}
                              />
                            )}

                            {selectedOption === "fileForEach" && (
                              <Alert
                                className="mt-3 text-center"
                                key="light"
                                variant="light"
                              >
                                Go to the next step!
                              </Alert>
                            )}
                          </div>
                        </Row>
                      </TabPane>

                      <TabPane tabId={2}>
                        <EnterPhoneDataComponent
                          key={0}
                          tempObject={tempObject}
                          setTempObject={setTempObject}
                          handleSendingAllData={handleSendingAllData}
                          fileForEachLoading={fileForEachLoading}
                          data={data}
                          setData={setData}
                          singleFileName={singleFileName}
                          singleTemplateData={singleTemplateData}
                          countryCode={countryCode}
                          setCountryCode={setCountryCode}
                          countryOptions={countryOptions}
                          headerVar={headerVar}
                          bodyVars={bodyVars}
                          selectedFile={selectedFile}
                          setSelectedFile={setSelectedFile}
                          handleSendingEachFile={handleSendingEachFile}
                          toggleModal={toggleModal}
                          setFormValuesArr={setFormValuesArr}
                        />
                      </TabPane>
                    </TabContent>
                  </div>

                  <div className="actions clearfix mt-2">
                    <ul className="text-center">
                      <li
                        className={
                          activeTab === 1 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab - 1)
                          }}
                        >
                          Previous
                        </Link>
                      </li>

                      {activeTab !== 2 && (
                        <li
                          onClick={() => {
                            selectedOptionText ===
                              "Upload a file for all numbers" &&
                              setData({
                                ...data,
                                clients: data.clients.map(client => ({
                                  ...client,
                                  fileName: singleFileName,
                                })),
                              })
                          }}
                          title={!selectedFile ? "Upload a file" : "next"}
                          className={`${
                            selectedOptionText === "Select a file option"
                              ? "next disabled"
                              : selectedOptionText ===
                                "Upload a file for each number"
                              ? "next"
                              : selectedOptionText ===
                                  "Upload a file for all numbers" &&
                                selectedFile &&
                                singleFileName
                              ? "next"
                              : "next disabled"
                          }`}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              selectedOptionText ===
                                "Upload a file for each number" &&
                                toggleTab(activeTab + 1)
                              selectedOptionText ===
                                "Upload a file for all numbers" &&
                                selectedFile &&
                                singleFileName &&
                                toggleTab(activeTab + 1)
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}

            {singleTemplateData.components[0].format !== "DOCUMENT" &&
              singleTemplateData.components[0].format !== "IMAGE" && (
                <div className="fields-container mb-2">
                  <EnterPhoneDataComponent
                    key={1}
                    tempObject={tempObject}
                    setTempObject={setTempObject}
                    handleSendingAllData={handleSendingAllData}
                    data={data}
                    setData={setData}
                    singleFileName={singleFileName}
                    singleTemplateData={singleTemplateData}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    countryOptions={countryOptions}
                    headerVar={headerVar}
                    bodyVars={bodyVars}
                    setSelectedFile={setSelectedFile}
                    handleSendingEachFile={handleSendingEachFile}
                    setFileForEachLoading={setFileForEachLoading}
                    selectedFile={selectedFile}
                    toggleModal={toggleModal}
                    setFormValuesArr={setFormValuesArr}
                  />
                </div>
              )}

            <div className="d-flex justify-content-end my-3">
              <button
                onClick={() => {
                  setSelectedFile(null)
                  setSingleTemplateData(null)
                  setData({ ...data, clients: [{}] })
                  setSingleFileName("")
                  setStep(1)
                }}
                className={`preivous-btn-enter-data d-inline-block`}
              >
                Previous
              </button>

              {activeTab === 2 ||
              (singleTemplateData.components[0].format !== "DOCUMENT" &&
                singleTemplateData.components[0].format !== "IMAGE") ? (
                <button
                  onClick={e => {
                    handleSubmit(e)
                  }}
                  title={
                    !singleTemplateData
                      ? "You need to choose an option first"
                      : "let's go!"
                  }
                  className={`next-btn-enter-data ms-3 ${
                    !singleTemplateData && "btn-disabled"
                  } d-inline-block`}
                  disabled={!singleTemplateData}
                >
                  Send
                </button>
              ) : null}
            </div>

            <Modal
              isOpen={isModalOpen}
              toggle={toggleModal}
              className="text-center"
              centered
            >
              {/* <ModalHeader toggle={toggleModal}>Confirm Action</ModalHeader> */}
              <ModalBody>
                <h3 className="my-4">Are you sure you want to proceed?</h3>
              </ModalBody>
              <ModalFooter className="justify-content-center py-1 mb-3 border-top-0">
                <button
                  className="preivous-btn-enter-data"
                  onClick={toggleModal}
                >
                  Cancel
                </button>

                <button
                  className="next-btn-enter-data"
                  style={{ padding: 0 }}
                  onClick={e => handleConfirm(e)}
                >
                  Confirm
                </button>
              </ModalFooter>
            </Modal>
          </div>
        </>
      </div>
    </>
  )
}

export default EnterDataSingleTemplatePreviewComponent
