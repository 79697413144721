import React, { useEffect, useState } from "react"
import axios from "axios"
import ChatToolsIcon from "components/Common/Icons/Chat/Conversation/ChatToolsIcon"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import ChangeClientName from "./ChangeClientName"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import configs from "config"
import TransferToTeamMember from "./TransferToTeamMember"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import TransferToAnotherTeam from "./TransferToAnotherTeam"
import ArchiveChat from "./ArchiveChat"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { useDispatch } from "react-redux"
import { deleteClosedTab, unAuthUser } from "store/actions"
import ControlSession from "./ControlSession"
const { Url, apiVersion } = configs.client

function ChatToolsDropdown({
  isChatOwner,
  setIsChatOwner,
  isChatArchived,
  isUserShareTeamChat,
  setClientName,
  setSendingTemplateLoading,
  lastSession,
}) {
  const { access, authorized, userData, openChatsTabs } = useSelector(
    state => ({
      access: state.Login.userAccess,
      authorized: state.Login.authorized,
      userData: state.Login.userData,
      openChatsTabs: state.Login.openChatsTabs,
    })
  )

  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [changeNameModal, setChangeNameModal] = useState(false)
  const [selectTeamMemberModal, setSelectTeamMemberModal] = useState(false)
  const [selectAnotherTeamModal, setSelectAnotherTeamModal] = useState(false)
  const [confirmArchiveChatModal, setConfirmArchiveChatModal] = useState(false)
  const [teamMembers, setTeamMembers] = useState([])
  const [anotherTeams, setAnotherTeams] = useState([])
  const [controlSessionModal, setControlSessionModal] = useState(false)

  const toggleControlSessionModal = () =>
    setControlSessionModal(!controlSessionModal)
  const toggleChangeNameModal = () => setChangeNameModal(!changeNameModal)
  const toggleSelectTeamMemeberModal = () =>
    setSelectTeamMemberModal(!selectTeamMemberModal)
  const toggleSelectAnotherTeamModal = () =>
    setSelectAnotherTeamModal(!selectAnotherTeamModal)
  const toggleconfirmArchiveChatModal = () =>
    setConfirmArchiveChatModal(!confirmArchiveChatModal)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const getUsersOrTeams = usersOrTeams => {
    let chatId = openChatsTabs.filter(chat => chat.client === params.id)[0]._id
    let currentTeamChatOwnerId = openChatsTabs.filter(
      chat => chat.client === params.id
    )[0].team

    let getTransferUrl =
      usersOrTeams === "users"
        ? `${Url}/${apiVersion}/${usersOrTeams}?type=chatTransfer&chat=${chatId}`
        : `${Url}/${apiVersion}/${usersOrTeams}`

    axios
      .get(getTransferUrl, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json; charset=utf8",
        },
      })
      .then(res => {
        let teamsObject = res.data.data

        if (usersOrTeams === "users") {
          setTeamMembers(res.data.data)
          toggleSelectTeamMemeberModal()
        }

        if (usersOrTeams === "teams") {
          const filteredTeams = teamsObject.teams.filter(
            team => team._id !== currentTeamChatOwnerId
          )

          setAnotherTeams({ teams: filteredTeams })
          toggleSelectAnotherTeamModal()
        }

        toast.success("Data retrieved successfully.")
      })
      .catch(err => {
        if (
          err.response.status == 401 ||
          err.response.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)

          toast.error("Failed to retrieve data.")
        }
      })
  }

  const conversationActions = (actionType, userId, teamId) => {
    setSendingTemplateLoading(true)

    const actionTypeBody =
      actionType === "takeOwnership"
        ? { type: "takeOwnership" }
        : actionType === "archive"
        ? { type: "archive" }
        : actionType === "transferToUser"
        ? { type: "transferToUser", user: userId }
        : actionType === "transferToTeam"
        ? { type: "transferToTeam", team: teamId }
        : null

    if (actionType === "archive") {
      // update the localstorage tabs
    }

    axios
      .patch(`${Url}/${apiVersion}/chats/${params.id}`, actionTypeBody, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json; charset=utf8",
        },
      })
      .then(res => {
        setSendingTemplateLoading(false)

        if (actionType === "archive") {
          handleArchiveTab()
          toast.success("Chat archived successfully.")
        } else if (actionType === "takeOwnership") {
          setIsChatOwner(true)
          toast.success("Chat ownership taken successfully.")
        } else {
          navigate("/")
          toast.success("Action performed successfully.")
        }
      })
      .catch(err => {
        setSendingTemplateLoading(false)

        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)

          toast.error("Failed to perform action.")
        }
      })
  }

  const handleArchiveTab = () => {
    navigate("/")
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="chat-tools">
      <ToastContainer />

      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
        <DropdownToggle caret className="chat-tools-toggler">
          <ChatToolsIcon />
        </DropdownToggle>

        <DropdownMenu end>
          <DropdownItem onClick={toggleChangeNameModal}>
            Change name
          </DropdownItem>
          <ChangeClientName
            changeNameModal={changeNameModal}
            toggleChangeNameModal={toggleChangeNameModal}
            setChangeNameModal={setChangeNameModal}
            setClientName={setClientName}
            setSendingTemplateLoading={setSendingTemplateLoading}
          />

          <DropdownItem
            disabled={
              isUserShareTeamChat
                ? isChatArchived
                  ? true
                  : isChatOwner
                  ? true
                  : false
                : true
            }
            onClick={() => conversationActions("takeOwnership")}
          >
            Take ownership
          </DropdownItem>

          <DropdownItem
            disabled={
              userData.role == "admin" ? false : !isChatOwner || isChatArchived
            }
            onClick={toggleconfirmArchiveChatModal}
          >
            Archive chat
          </DropdownItem>
          <ArchiveChat
            conversationActions={conversationActions}
            confirmArchiveChatModal={confirmArchiveChatModal}
            toggleconfirmArchiveChatModal={toggleconfirmArchiveChatModal}
          />

          <DropdownItem
            disabled={userData.role === "admin" ? false : !isChatOwner}
            onClick={() => {
              getUsersOrTeams("users")
            }}
          >
            Transfer to a team member
          </DropdownItem>
          <TransferToTeamMember
            conversationActions={conversationActions}
            selectTeamMemberModal={selectTeamMemberModal}
            toggleSelectTeamMemeberModal={toggleSelectTeamMemeberModal}
            teamMembers={teamMembers}
          />

          <DropdownItem
            disabled={userData.role === "admin" ? false : !isChatOwner}
            onClick={() => getUsersOrTeams("teams")}
          >
            Transfer to another team
          </DropdownItem>
          <TransferToAnotherTeam
            conversationActions={conversationActions}
            selectAnotherTeamModal={selectAnotherTeamModal}
            toggleSelectAnotherTeamModal={toggleSelectAnotherTeamModal}
            anotherTeams={anotherTeams}
          />

          {lastSession
            ? userData.secret && (
                <DropdownItem
                  disabled={!isChatOwner}
                  onClick={toggleControlSessionModal}
                >
                  Turn to {lastSession?.secret ? "Public" : "Secret"}
                </DropdownItem>
              )
            : ""}
          <ControlSession
            controlSessionModal={controlSessionModal}
            toggleControlSessionModal={toggleControlSessionModal}
            session={lastSession}
            setSendingTemplateLoading={setSendingTemplateLoading}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default ChatToolsDropdown
