import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import moment from "moment"
import configs from "../../config"
const { Url, apiVersion } = configs.client
import { useSelector, useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import TicketPerformanceActions from "./TicketPerformanceComponents/TicketPerformanceActions"
import TicketPerformanceTable from "./TicketPerformanceComponents/TicketPerformanceTable"
import TicketPerformanceReportsFilters from "./TicketPerformanceComponents/TicketPerformanceReportsFilters"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function TicketReports() {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [isloading, setIsLoading] = useState(false)
  const [TicketPerformanceData, setTicketPerformanceData] = useState([])

  const [selectedTeam, setSelectedTeam] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const cancelTokenSource = useRef(null)

  const fetchTicketPerformanceData = () => {
    let getBody = {}

    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to a new request."
      )
    }

    cancelTokenSource.current = axios.CancelToken.source()

    if (startDate) getBody.startDate = moment(startDate).format("YYYY-MM-DD")
    if (endDate) getBody.endDate = moment(endDate).format("YYYY-MM-DD")
    if (selectedTeam)
      getBody.selectedTeams = selectedTeam?.map(obj => obj.value).join(",")
    if (selectedUser)
      getBody.selectedUsers = selectedUser?.map(obj => obj.value).join(",")

    const fetchPerformanceURL = `${Url}/${apiVersion}/ticket-performance`

    setIsLoading(true)
    axios
      .get(fetchPerformanceURL, {
        params: getBody,
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
        cancelToken: cancelTokenSource.current.token,
      })
      .then(res => {
        setIsLoading(false)
        setTicketPerformanceData(res.data.data.performances)
      })
      .catch(err => {
        setIsLoading(false)

        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message)
        } else {
          if (
            err.response?.status === 401 ||
            err.response?.status === 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          }

          console.log("Error is", err)
          toast.error("An error occurred while fetching data.")
        }
      })
  }

  useEffect(() => {
    if (selectedTeam && selectedTeam.length > 0) {
      setSelectedUser(null)
    }
  }, [selectedTeam])

  useEffect(() => {
    fetchTicketPerformanceData()
  }, [selectedUser, selectedTeam, startDate, endDate])

  const handleResetFilters = () => {
    setSelectedTeam(null)
    setSelectedUser(null)
    setStartDate(null)
    setEndDate(null)
  }

  const isFiltered =
    selectedTeam?.length > 0 ||
    selectedUser?.length > 0 ||
    startDate !== null ||
    endDate !== null

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="user-setting-page">
      <ToastContainer />

      <div className="performance-page">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-0">Ticket Performance</h4>
          <TicketPerformanceActions
            isFiltered={isFiltered}
            handleResetFilters={handleResetFilters}
            TicketPerformanceData={TicketPerformanceData}
            selectedTeam={selectedTeam}
            selectedUser={selectedUser}
            startDate={startDate}
            endDate={endDate}
            access={access}
          />
        </div>

        <TicketPerformanceReportsFilters
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          endDate={endDate}
        />

        <TicketPerformanceTable
          isloading={isloading}
          TicketPerformanceData={TicketPerformanceData}
        />
      </div>
    </div>
  )
}
