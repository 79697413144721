import React, { useEffect, useState } from "react"
import axios from "axios"
import { useSelector } from "react-redux"
import { Spinner } from "reactstrap"

import configs from "../../../src/config"
const { Url, apiVersion } = configs.client

import { ToastContainer, toast } from "react-toastify" // Import Toastify
import "react-toastify/dist/ReactToastify.css" // Import Toastify styles

function SendSingleFileForEachComponent({
  fileForEachLoading,
  clientIdx,
  data,
  setData,
  setFileForEachLoading,
  selectedFile,
  setSelectedFile,
  setFieldValue,
}) {
  const { access } = useSelector(state => ({
    access: state.Login?.userAccess,
  }))

  const [localSelectedFile, setLocalSelectedFile] = useState(null)
  const [loading, setLoading] = useState(fileForEachLoading)
  const [uploaded, setUploaded] = useState(false)

  useEffect(() => {
    setLocalSelectedFile(selectedFile)
  }, [selectedFile])

  const formData = new FormData()

  let uploadQueue = Promise.resolve()

  const handleSendingEachFile = async clientIdx => {
    uploadQueue = uploadQueue.then(async () => {
      formData.append("file", localSelectedFile)
      setLoading(true)

      try {
        const res = await axios.post(
          `${Url}/${apiVersion}/upload/single`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${access}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )

        setFieldValue(`formValues[${clientIdx}].fileName`, res.data.file)

        setData(prevData => ({
          ...prevData,
          clients: prevData.clients.map((client, index) => {
            if (index === clientIdx) {
              return {
                ...client,
                fileName: res.data.file,
              }
            }
            return client
          }),
        }))

        setLocalSelectedFile(null)
        setUploaded(true)
        setLoading(false)

        toast.success("File uploaded successfully!") // Success message
      } catch (err) {
        console.error("Error uploading file:", err)
        toast.error("Failed to upload file. Please try again.") // Error message

        setLocalSelectedFile(null)
        setLoading(false)
      }
    })

    await uploadQueue
  }
  useEffect(() => {}, [fileForEachLoading])

  return (
    <>
      <ToastContainer />

      <button
        disabled={uploaded}
        className={`btn-send-file ms-1 text-white ${
          (uploaded || !localSelectedFile) && "btn-upload-disabled"
        } ${uploaded && "btn-send-file-uploaded"}`}
        onClick={e => {
          e.preventDefault()
          localSelectedFile && handleSendingEachFile(clientIdx)
        }}
      >
        {loading ? (
          <Spinner size="sm" />
        ) : uploaded ? (
          <i className="bx bx-check bx-xs"></i>
        ) : (
          <i className="bx bx-send bx-xs"></i>
        )}
      </button>
    </>
  )
}

export default SendSingleFileForEachComponent
