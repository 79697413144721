import React, { useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap"
import { useFormik } from "formik"
import axios from "axios"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import ResetChangeNameModal from "./ResetChangeNameModal"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import configs from "config"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
const { Url, apiVersion } = configs.client

function ChangeClientName({
  changeNameModal,
  toggleChangeNameModal,
  setClientName,
  setSendingTemplateLoading,
}) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()
  const params = useParams()
  const [resetContactNameModal, setResetContactNameModal] = useState(false)
  const toggleResetContactNameModal = () =>
    setResetContactNameModal(!resetContactNameModal)

  const changeContactNameMethod = (actionType, newName) => {
    setSendingTemplateLoading(true)
    const requestBody = {
      type: actionType,
      name: newName,
    }

    axios
      .patch(`${Url}/${apiVersion}/contacts/${params.id}`, requestBody, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json; charset=utf8",
        },
      })
      .then(res => {
        setSendingTemplateLoading(false)
        toggleChangeNameModal()
        setClientName(res.data?.contact?.name)

        toast.success("Contact name changed successfully!")
      })
      .catch(err => {
        setSendingTemplateLoading(false)

        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)

          toast.error("Failed to change the contact name.")
        }
      })

    actionType === "reset" && toggleChangeNameModal()
  }

  const formik = useFormik({
    initialValues: {
      displayName: "",
    },
    validationSchema: Yup.object({
      displayName: Yup.string().required("Required"),
    }),
    onSubmit: values => {
      changeContactNameMethod("update", values.displayName)
      toggleChangeNameModal()
    },
  })

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      <ToastContainer />

      <Modal
        className="chat-tools-change-name"
        isOpen={changeNameModal}
        toggle={toggleChangeNameModal}
        centered
      >
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader toggle={toggleChangeNameModal}>
            Change Display Name
          </ModalHeader>
          <ModalBody>
            <Input
              type="text"
              name="displayName"
              id="displayName"
              placeholder="Enter new display name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.displayName}
            />
            {formik.touched.displayName && formik.errors.displayName && (
              <div style={{ color: "red" }}>{formik.errors.displayName}</div>
            )}
          </ModalBody>
          <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
            <Button
              className="me-auto"
              color="danger"
              style={{ padding: "0.1875rem 0.875rem" }}
              onClick={toggleResetContactNameModal}
            >
              Reset
            </Button>
            <ResetChangeNameModal
              resetContactNameModal={resetContactNameModal}
              changeContactNameMethod={changeContactNameMethod}
              toggleResetContactNameModal={toggleResetContactNameModal}
            />
            <Button
              color="secondary"
              outline
              onClick={toggleChangeNameModal}
              style={{ padding: "0.1875rem 0.875rem" }}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              type="submit"
              style={{ padding: "0.1875rem 0.875rem" }}
            >
              Change
            </Button>{" "}
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}

export default ChangeClientName
