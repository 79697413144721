import React, { useState } from "react"
import ResetFiltersIcon from "components/Common/Icons/SettingIcons/ResetFiltersIcon"
import DownloadSheetIcon from "components/Common/Icons/BroadcastReport/DownloadSheetIcon"
import * as XLSX from "xlsx"
import axios from "axios"
import { useDispatch } from "react-redux"
import { unAuthUser } from "store/actions"
import configs from "config"
const { Url, apiVersion } = configs.client
import moment from "moment"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function PerformanceReportsActions({
  handleResetFilters,
  isFiltered,
  PerformancesData,
  selectedTeam,
  selectedUser,
  startDate,
  endDate,
  access,
}) {
  const dispatch = useDispatch()
  const [exportingData, setExportingData] = useState(false)

  const generateExportUrl = () => {
    const params = {
      type: "download",
    }

    if (startDate) {
      params.startDate = moment(startDate).format("MM-DD-YYYY")
    }

    if (endDate) {
      params.endDate = moment(endDate).format("MM-DD-YYYY")
    }

    if (selectedTeam && selectedTeam.length > 0) {
      params.selectedTeams = selectedTeam.map(obj => obj.value).join(",")
    }

    if (selectedUser && selectedUser.length > 0) {
      params.selectedUsers = selectedUser.map(obj => obj.value).join(",")
    }

    // Construct query string
    const queryString = new URLSearchParams(params).toString()
    return `${Url}/${apiVersion}/performance?${queryString}`
  }

  const exportToExcel = () => {
    setExportingData(true)

    axios
      .get(generateExportUrl(), {
        headers: {
          Authorization: `Bearer ${access}`,
        },
        responseType: "blob",
      })
      .then(response => {
        setExportingData(false)

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        const contentDisposition = response.headers["content-disposition"]
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1]
          : `PerformanceReports_${new Date().toISOString()}.xlsx`

        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)

        toast.success("Excel file exported successfully!")
      })
      .catch(err => {
        setExportingData(false)

        if (
          err.response?.status === 401 ||
          err.response?.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          const errorMessage =
            err.response?.data?.message || "Failed to export Excel file."
          toast.error(errorMessage)

          console.error("Error exporting performance reports:", err)
        }
      })
  }

  return (
    <div>
      <ToastContainer />

      <div className="d-flex justify-content-center align-items-center gap-2">
        <button
          onClick={exportToExcel}
          className="border-0 bg-transparent gap-2 d-flex justify-content-around align-items-center download-filters-report"
          disabled={exportingData}
        >
          <DownloadSheetIcon />
          {exportingData ? "Exporting..." : "Download Excel Sheet"}
        </button>
        {isFiltered && (
          <button
            onClick={handleResetFilters}
            className="border-0 bg-transparent d-flex justify-content-around align-items-center reset-filters-report"
          >
            <ResetFiltersIcon />
          </button>
        )}
      </div>
    </div>
  )
}
