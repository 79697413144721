import React, { useEffect, useState } from "react"
import { Card, CardBody, Spinner } from "reactstrap"
import axios from "axios"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import NoMessageFound from "./../../../components/Common/Icons/Chat/Notes/no-notes-found.png"

import configs from "config"
const { Url, apiVersion } = configs.client

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import SingleHistory from "./SingleHistory"
import CustomSpinner from "components/Common/CustomSpinner"
import InfiniteScroll from "react-infinite-scroll-component"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

function ChatHistory() {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const [history, setHistory] = useState([])
  const [historyLoading, setHistoryLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [noHistoryFound, setNoHistoryFound] = useState(false)

  const params = useParams()
  const dispatch = useDispatch()

  const fetchingHistory = () => {
    setHistoryLoading(true)
    setNoHistoryFound(false)

    axios
      .get(`${Url}/${apiVersion}/chats/${params.id}/histories?page=1`, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        setTotalPages(res.data.data.totalPages)
        page > 1 && setPage(1)
        setHistory(res.data.data.histories)
        if (res.data.data.histories.length === 0) {
          setNoHistoryFound(true)
        }
        setHistoryLoading(false)
      })
      .catch(err => {
        setHistoryLoading(false)
        setNoHistoryFound(true)

        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to load chat history.")
        }
      })
  }

  const fetchMoreData = () => {
    const nextPage = page + 1

    axios
      .get(
        `${Url}/${apiVersion}/chats/${params.id}/histories?page=${page + 1}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        setPage(nextPage)
        setHistory(res.data.data.histories)
      })
      .catch(err => {
        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to load more chat history.")
        }
      })
  }

  useEffect(() => {
    fetchingHistory()
  }, [params.id])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div
      className={`d-flex flex-column flex-grow-1 pt-1 tab-content-notes ${
        (historyLoading || noHistoryFound) && "justify-content-center"
      } chat-history`}
    >
      <ToastContainer />

      <Card id="historyDiv">
        <CardBody className="p-0">
          {noHistoryFound ? (
            <div className="align-self-center d-flex flex-column justify-content-center mw-50 my-auto">
              <img
                className="mx-auto"
                style={{ height: "13.25rem" }}
                src={NoMessageFound}
              />
              <h6 className="mx-auto font-size-20">No History Yet!</h6>
              <span className="mx-auto font-size-16 text-muted">
                No history actions has been regestered yet.
              </span>
            </div>
          ) : historyLoading ? (
            <div className="d-flex align-content-center">
              <CustomSpinner />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={history.length}
              next={fetchMoreData}
              hasMore={page < totalPages}
              loader={
                <div className="text-center">
                  <Spinner color="secondary" />
                </div>
              }
              scrollableTarget="historyDiv"
              className="d-flex flex-column"
              style={{
                margin: "0",
                padding: "0",
                overflow: "hidden",
              }}
            >
              <ul className="list-group">
                {Array.isArray(history) &&
                  history.map((item, index) => (
                    <SingleHistory item={item} key={index} />
                  ))}
              </ul>
            </InfiniteScroll>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default ChatHistory
