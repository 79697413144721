import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import * as Yup from "yup"
import { Form, Formik, Field, FieldArray, ErrorMessage } from "formik"

import axios from "axios"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import DropdownFields from "./DropdownFields"
import CustomSpinner from "components/Common/CustomSpinner"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import configs from "config"
const { Url, apiVersion } = configs.client

function EditField() {
  const { access, authorized } = useSelector(state => ({
    access: state.Login?.userAccess,
    authorized: state.Login.authorized,
  }))

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()

  const [editUserPreview, setEditUserPreview] = useState(false)
  const [creatingField, setCreatingField] = useState(false)
  const [fieldData, setFieldData] = useState({})
  const [loadingFieldData, setLoadingFieldData] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string(),
    endUserPermission: Yup.string().required("Required"),
    dropdownValues:
      fieldData.type?.value === "dropdown"
        ? Yup.array().of(Yup.string().required("Required"))
        : "",
    defaultValue:
      fieldData.type?.value === "dropdown"
        ? Yup.string().required("Required")
        : "",
  })

  const fetchFieldData = () => {
    axios
      .get(`${Url}/${apiVersion}/fields/${params.fieldId}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        // setFields(res.data.data.fields)
        setLoadingFieldData(false)
        setFieldData(res.data.data.field)
        res.data.data.field.status === "active" && settoggleSwitchSize(true)
        // setLoading(false)
      })
      .catch(err => {
        setLoadingFieldData(false)

        if (
          err.response.status == 401 ||
          err.response.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("An error occurred. Please try again.")
        }
      })
  }

  useEffect(() => {
    params.fieldId && fetchFieldData() // Run the fetch function if fieldId is available
  }, [params.fieldId, access])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      <ToastContainer />

      <Container fluid className="py-4 px-5">
        <div className="d-flex align-items-center mb-4">
          <i
            onClick={() => navigate("/ticket/fields")}
            className="mdi mdi-arrow-left text-secondary py-0 px-3"
            style={{ cursor: "pointer" }}
          ></i>

          <div className="flex-grow-1">
            <Breadcrumbs title="Fields" breadcrumbItem="Edit Field" />
          </div>
        </div>

        {loadingFieldData ? (
          <Row>
            <Col md={4} className="mx-auto">
              <CustomSpinner />
            </Col>
          </Row>
        ) : (
          <Formik
            initialValues={{
              // type: fieldData.type?._id || "",
              name: fieldData.name || "",
              description: fieldData.description || "",
              required: fieldData.required || false,
              solveRequired: fieldData.solveRequired || false,
              tag: fieldData.tag || false,
              endUserPermission: fieldData.endUserPermission || "",
              dropdownValues: fieldData.values || [],
              defaultValue: fieldData.defaultValue || "",
              endUserView: fieldData.endUserView || "",
              status: fieldData.status || true,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              setCreatingField(true)

              let dataToSend = {
                // type: fieldData.type?._id,
                name: values.name,
                description: values.description,
                required: values.required,
                solveRequired: values.solveRequired,
                endUserPermission: values.endUserPermission,
                endUserView: values.endUserView
                  ? values.endUserView
                  : values.name,
                status: toggleSwitchSize ? "active" : "inactive",
              }

              if (
                fieldData.type?.value === "dropdown" ||
                fieldData.type?.value === "multi-select"
              ) {
                dataToSend.values = values.dropdownValues
                dataToSend.defaultValue = values.defaultValue

                if (fieldData.type?.value === "dropdown") {
                  dataToSend.tag = values.tag
                }
              }

              axios
                .patch(
                  `${Url}/${apiVersion}/fields/${params.fieldId}`,
                  dataToSend,
                  {
                    headers: {
                      Authorization: `Bearer ${access}`,
                      "Content-Type": "application/json; charset=utf8",
                    },
                  }
                )
                .then(res => {
                  navigate("/ticket/fields")
                  console.log("res")
                  setCreatingField(false)
                })
                .catch(err => {
                  setCreatingField(false)

                  if (
                    err.response.status == 401 ||
                    err.response.status == 0 ||
                    !err.response.status
                  ) {
                    dispatch(unAuthUser())
                  } else {
                    console.log(err)
                    toast.error("An error occurred. Please try again.")
                  }
                })
            }}
          >
            {({ setFieldValue, values, errors }) => (
              <Form className="position-relative mt-2">
                {creatingField && (
                  <div className="sending-template-overlay-loading d-flex justify-content-center align-items-center">
                    <div>
                      <CustomSpinner />
                    </div>
                  </div>
                )}

                <Row className="gx-5">
                  <Col md={8}>
                    <Card className="rounded-3 overflow-hidden">
                      <CardHeader className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          {fieldData.type?.value === "dropdown" && (
                            <i className="mdi mdi-form-dropdown fa-3x me-3"></i>
                          )}
                          {fieldData.type?.value === "checkbox" && (
                            <i className="mdi mdi-checkbox-marked-outline fa-3x me-3"></i>
                          )}
                          {fieldData.type?.value === "text-area" && (
                            <i className="mdi mdi-format-align-left fa-3x me-3"></i>
                          )}
                          {fieldData.type?.value === "multi-select" && (
                            <i className="bx bx-select-multiple fa-3x me-3"></i>
                          )}
                          {fieldData.type?.value === "text" && (
                            <i className="mdi mdi-format-text fa-3x me-3"></i>
                          )}
                          {fieldData.type?.value === "number" && (
                            <i className="bx bx-hash fa-3x me-3"></i>
                          )}
                          {fieldData.type?.value === "date" && (
                            <i className="mdi mdi-calendar-blank-outline fa-3x me-3"></i>
                          )}
                          {fieldData.type?.value === "decimal" && (
                            <i className="mdi mdi-decimal fa-3x me-3"></i>
                          )}

                          <div>
                            <h4 className="mb-0">
                              {fieldData.type?.name + " Field"}
                            </h4>
                            <span className="lead text-muted">
                              {fieldData.type?.description}
                            </span>
                          </div>
                        </div>

                        <div
                          className="form-check form-switch form-switch-md mb-3"
                          title={
                            fieldData.forms.length > 0
                              ? "This field cannot be deactivated."
                              : "Control the field status."
                          }
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizesm"
                            checked={toggleSwitchSize}
                            onClick={e => {
                              settoggleSwitchSize(!toggleSwitchSize)
                            }}
                            disabled={fieldData.forms.length > 0}
                          />
                          <label
                            className="form-check-label font-size-16"
                            htmlFor="customSwitchsizesm"
                          >
                            {toggleSwitchSize ? "active" : "inactive"}
                          </label>
                        </div>
                      </CardHeader>
                      <CardBody
                        style={{ maxHeight: "73vh", overflowY: "auto" }}
                      >
                        <FormGroup>
                          <Label for="fieldName">Name</Label>
                          <Field
                            id="fieldName"
                            name="name"
                            placeholder="Enter field name"
                            type="text"
                            as={Input}
                          />
                          <ErrorMessage
                            component="div"
                            style={{ color: "red", fontSize: "0.875em" }}
                            name="name"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for="description">Description</Label>
                          <Field
                            id="description"
                            name="description"
                            type="textarea"
                            placeholder="Enter field description"
                            rows="4"
                            as={Input}
                          />
                          <ErrorMessage
                            component="div"
                            style={{ color: "red", fontSize: "0.875em" }}
                            name="description"
                          />
                        </FormGroup>

                        <FormGroup check inline>
                          <Field
                            id="required"
                            name="required"
                            type="checkbox"
                            as={Input}
                            onClick={() =>
                              setFieldValue("required", !values.required)
                            }
                          />
                          <Label for="required" check>
                            Required to create the ticket
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            id="solveRequired"
                            name="solveRequired"
                            type="checkbox"
                            as={Input}
                            checked={values.solveRequired}
                            onClick={() =>
                              setFieldValue(
                                "solveRequired",
                                !values.solveRequired
                              )
                            }
                          />
                          <Label for="solveRequired" check>
                            Required to solve the ticket
                          </Label>
                        </FormGroup>
                        {fieldData.type?.value === "dropdown" && (
                          <FormGroup check inline>
                            <Input
                              id="tag"
                              name="tag"
                              type="checkbox"
                              as={Input}
                              checked={values.tag}
                              onClick={() => setFieldValue("tag", !values.tag)}
                            />
                            <Label for="tag" check>
                              Make this field a new tag
                            </Label>
                          </FormGroup>
                        )}

                        <FormGroup tag="fieldset" className="mt-3">
                          <legend className="font-size-16">Permissions</legend>

                          <FormGroup
                            check
                            className="d-flex align-items-center gap-2 mt-2"
                          >
                            <Input
                              name="endUserPermission"
                              type="radio"
                              id="hidden"
                              value="hidden"
                              checked={values.endUserPermission === "hidden"}
                              onClick={() =>
                                setFieldValue("endUserPermission", "hidden")
                              }
                            />
                            <Label
                              for="hidden"
                              check
                              className="d-flex flex-column"
                            >
                              <span>Hide</span>
                              <span className="text-muted">
                                The end user can't see this field
                              </span>
                            </Label>
                          </FormGroup>

                          <FormGroup
                            check
                            className="d-flex align-items-center gap-2 mt-2"
                          >
                            <Input
                              name="endUserPermission"
                              type="radio"
                              id="view"
                              value="view"
                              checked={values.endUserPermission === "view"}
                              onClick={() =>
                                setFieldValue("endUserPermission", "view")
                              }
                            />
                            <Label
                              for="view"
                              check
                              className="d-flex flex-column"
                            >
                              <span>Show</span>
                              <span className="text-muted">
                                The end user can see this field
                              </span>
                            </Label>
                          </FormGroup>

                          <FormGroup
                            check
                            className="d-flex align-items-center gap-2 mt-2"
                          >
                            <Input
                              name="endUserPermission"
                              type="radio"
                              id="edit"
                              value="edit"
                              checked={values.endUserPermission === "edit"}
                              onClick={() =>
                                setFieldValue("endUserPermission", "edit")
                              }
                            />
                            <Label
                              for="edit"
                              check
                              className="d-flex flex-column"
                            >
                              <span>Edit</span>
                              <span className="text-muted">
                                The end user can edit this field
                              </span>
                            </Label>
                          </FormGroup>

                          <ErrorMessage
                            component="div"
                            style={{ color: "red", fontSize: "0.875em" }}
                            name="endUserPermission"
                          />
                        </FormGroup>

                        {(fieldData.type?.value === "dropdown" ||
                          fieldData.type?.value === "multi-select") && (
                          <DropdownFields values={values.dropdownValues} />
                        )}
                      </CardBody>
                    </Card>
                  </Col>

                  <Col
                    md={4}
                    className="d-flex flex-column justify-content-between"
                  >
                    <Card className="rounded-3 overflow-hidden">
                      <CardHeader>
                        <h4 className="mb-0">Preview</h4>
                      </CardHeader>
                      <CardBody
                        style={{
                          height: "50vh",
                          maxHeight: "50vh",
                          overflowY: "auto",
                        }}
                      >
                        <p className="text-muted lead mb-4">
                          This is what end user will see when they interact with
                          this field
                        </p>

                        <div>
                          <div className="d-flex align-items-center mb-2">
                            <h5 className="font-size-16 mb-0">
                              {editUserPreview ? (
                                <Field
                                  name="endUserView"
                                  id="endUserView"
                                  placeholder={fieldData.name}
                                  as={Input}
                                />
                              ) : fieldData.endUserView ? (
                                fieldData.endUserView
                              ) : (
                                values.name
                              )}
                            </h5>

                            <i
                              className="bx bx-edit ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                setEditUserPreview(!editUserPreview)
                              }
                            ></i>
                          </div>

                          {fieldData.type?.value === "checkbox" ? (
                            <div className="d-flex justify-content-between align-items-center border border-2 p-2 rounded-3">
                              <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <i className="bx bx-square me-2"></i>

                                  <span>Enter a name</span>
                                </div>
                              </div>

                              {fieldData.type?.value === "dropdown" && (
                                <i className="bx bx-chevron-down ms-3"></i>
                              )}
                            </div>
                          ) : null}

                          {fieldData.type?.value === "text-area" ? (
                            <div
                              className="d-flex justify-content-between align-items-start border border-2 p-2 rounded-3"
                              style={{ minHeight: "6.25rem" }}
                            >
                              <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <span>Enter a name</span>
                                </div>
                              </div>

                              {fieldData.type?.value === "dropdown" && (
                                <i className="bx bx-chevron-down ms-3"></i>
                              )}
                            </div>
                          ) : null}

                          {(fieldData.type?.value === "dropdown" ||
                            fieldData.type?.value === "multi-select" ||
                            fieldData.type?.value === "text" ||
                            fieldData.type?.value === "number" ||
                            fieldData.type?.value === "decimal" ||
                            fieldData.type?.value === "date") && (
                            <div className="d-flex justify-content-between align-items-center border border-2 p-2 rounded-3">
                              <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                                <span>Choose a value</span>
                              </div>

                              {(fieldData.type?.value === "dropdown" ||
                                fieldData.type?.value === "multi-select") && (
                                <i className="bx bx-chevron-down ms-3"></i>
                              )}
                            </div>
                          )}

                          {(fieldData.type?.value === "dropdown" ||
                            fieldData.type?.value === "multi-select") && (
                            <div
                              className="d-flex flex-column gap-1 px-2 bg-light rounded-3 py-1"
                              style={{ maxHeight: "30vh", overflowY: "auto" }}
                            >
                              {values.dropdownValues?.map((value, index) => (
                                <span key={index} value={value}>
                                  {value}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </CardBody>
                    </Card>

                    <div className="d-flex gap-2 justify-content-end">
                      <Button
                        onClick={() => navigate("/ticket/fields")}
                        variant="secondary"
                        outline
                        className="font-size-16 py-1"
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        variant="secondary"
                        className="font-size-16 py-1"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </>
  )
}

export default EditField
