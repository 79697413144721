import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  Alert,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import EditTicketInfo from "./EditTicketInfo"
import PastTickets from "./PastTickets"
import TicketProjectInfo from "./TicketProjectInfo"
import TicketLog from "./TicketLog"
import { useDispatch, useSelector } from "react-redux"
import configs from "config"
import { useParams } from "react-router-dom"
const { Url, apiVersion } = configs.client
import axios from "axios"
import FormDetails from "./FormDetails"
import SingleComment from "./TicketComments/SingleComment"
import CustomSpinner from "components/Common/CustomSpinner"
import NoMessageFound from "./../../../components/Common/Icons/Chat/Conversation/no-message-found.png"
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import socket from "socket"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function ViewTicket() {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login?.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const [drp_link, setdrp_link] = useState(false)
  const [selectedColumnRightSide, setSeleectedColumnRightSide] = useState(1)
  const [ticketData, setTicketData] = useState(null)
  const [comments, setComments] = useState(null)
  const [commentText, setCommentText] = useState("")
  const [isPrivate, setIsPrivate] = useState(true)
  const [teams, setTeams] = useState([])
  const [statuses, setStatuses] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [loadingComments, setLoadingComments] = useState(true)
  const [noCommentsFound, setNoCommentsFound] = useState(true)
  const [doingEditsLoading, setDoingEditsLoading] = useState(false)
  const [takeOwnerShipModal, setTakeOwnerShipModal] = useState(false)
  const [ticketLogFromSocket, setTicketLogFromSocket] = useState([])
  const [ticketPastTicketsSocket, setTicketPastTicketsSocket] = useState([])
  const [sendingCommentLoading, setSendingCommentLoading] = useState(false)

  const toggleTakeOwnerShipModal = () => {
    setTakeOwnerShipModal(!takeOwnerShipModal)
  }

  document.title = `Ticket No #${
    ticketData?.order ? ticketData.order : "Ticket"
  } | ChatPlus`

  const params = useParams()
  const commentsEndRef = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const fetchTeams = () => {
    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setTeams(res.data.data.teams)
      })
      .catch(err => {
        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to fetch teams. Please try again.")
        }
      })
  }

  const fetchTicketData = () => {
    setLoadingData(true)

    axios
      .get(`${Url}/${apiVersion}/tickets/${params.ticketId}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setTicketData(res.data.data.ticket)
        setLoadingData(false)
      })
      .catch(err => {
        setLoadingData(false)

        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          toast.error("Failed to load ticket data. Please try again.")
          console.log(err)
        }
      })
  }

  const tackeTiccketOwnership = () => {
    setDoingEditsLoading(true)

    axios
      .patch(
        `${Url}/${apiVersion}/tickets/${params.ticketId}/take-ownership`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        setDoingEditsLoading(false)
        toast.success("Ticket ownership successfully taken.")
      })
      .catch(err => {
        setDoingEditsLoading(false)

        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to take ticket ownership. Please try again.")
        }
      })
  }

  const fetchTicketStatuses = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketStatuses?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setStatuses(res.data.data.statuses)
      })
      .catch(err => {
        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const fetchTicketComments = () => {
    setNoCommentsFound(false)

    axios
      .get(`${Url}/${apiVersion}/tickets/${params.ticketId}/comments`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setComments(res.data.data.comments)
        if (res.data.data.comments.length === 0) {
          setNoCommentsFound(true)
        }

        setLoadingComments(false)
      })
      .catch(err => {
        setLoadingComments(false)

        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to load comments. Please try again.")
        }
      })
  }

  const isImage = filename => {
    return /\.(jpg|jpeg|png|gif)$/.test(filename)
  }

  const renderSelectedFiles = () => {
    return (
      <div
        className="d-flex align-items-center p-2 shadow"
        style={{
          backgroundColor: "#F2F2F7",
          borderRadius: "10px",
          overflowX: "auto",
          whiteSpace: "nowrap",
          maxWidth: "70%",
          position: "absolute",
          left: "0",
          bottom: "100%",
          border: "1px solid rgba(0,0,0,0.20)",
        }}
      >
        {selectedFiles.map((file, index) => (
          <div
            key={index}
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              width: "100px",
              height: "100px",
              margin: "5px",
              padding: "5px",
              backgroundColor: "#FFF",
              borderRadius: "5px",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
              position: "relative",
            }}
          >
            <button
              type="button"
              onClick={() => handleRemoveFile(index)}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              <i className="mdi mdi-close-circle text-danger"></i>
            </button>
            {isImage(file.name) ? (
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "2px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <i
                className={`mdi mdi-file-document-outline mdi-36px text-secondary`}
              ></i>
            )}
            <small style={{ fontSize: "10px", textAlign: "center" }}>
              {file.name.length > 10
                ? `${file.name.substring(0, 7)}...`
                : file.name}
            </small>
            <small style={{ fontSize: "10px", textAlign: "center" }}>
              Size: {(file.size / 1024).toFixed(2)} KB
            </small>
          </div>
        ))}
      </div>
    )
  }

  const handleRemoveFile = index => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index))
  }

  const handleSendComment = statusId => {
    setSendingCommentLoading(true)
    const commentType = isPrivate ? "note" : "public"

    let formData = new FormData()

    formData.append("type", commentType)

    if (commentText) {
      formData.append("text", commentText)
    }

    if (statusId) {
      formData.append("status", statusId)
    }

    selectedFiles.forEach(file => {
      formData.append("files", file)
    })

    axios
      .post(
        `${Url}/${apiVersion}/tickets/${params.ticketId}/comments`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(res => {
        setSendingCommentLoading(false)
        fetchTicketComments()
        // statusId && fetchTicketData()
        setCommentText("")
        setSelectedFiles([]) // Clear the selected files after sending
      })
      .catch(err => {
        setSendingCommentLoading(false)
        console.log(err)
        toast.error("Failed to send comment. Please try again.")
      })
  }

  const handleFileSelect = event => {
    const files = Array.from(event.target.files)
    setSelectedFiles(prevFiles => [...prevFiles, ...files])
  }

  useEffect(() => {
    if (socket) {
      const handleUpdating = () => {
        socket.emit("client_to_server", {
          type: "ticket",
          ticketID: params.ticketId,
        })
      }

      const handleServerToClient = response => {
        // if (response.teamTicketsfilters || response.userTicketsfilters) {
        //   setTicketsFilterNumbers(response)
        // }

        // console.log("response ticket socket", response)

        response.ticket && setTicketData(response.ticket)
        response.ticketLogs && setTicketLogFromSocket(response.ticketLogs)
        response.pastTickets && setTicketPastTicketsSocket(response.pastTickets)
        response.comments && setComments(response.comments)
      }

      socket.on("updatingTickets", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      // Cleanup function to remove listeners on component unmount or dependencies change
      return () => {
        socket.off("updatingTickets", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [socket])

  useEffect(() => {
    fetchTicketData()
    fetchTeams()
    fetchTicketComments()
    fetchTicketStatuses()
  }, [params.ticketId])

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [comments])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="py-3 px-1">
      <ToastContainer />

      <Container fluid className="py-4 px-2 pt-2 position-relative">
        <div className="d-flex align-items-center mb-3">
          <i
            onClick={() => navigate("/ticket/list")}
            className="mdi mdi-arrow-left text-secondary py-0 px-3"
            style={{ cursor: "pointer" }}
          ></i>

          <div className="flex-grow-1">
            <Breadcrumbs title="Tickets" breadcrumbItem="View Ticket" />
          </div>
        </div>

        {doingEditsLoading && (
          <div className="sending-template-overlay-loading d-flex justify-content-center align-items-center">
            <div>
              <CustomSpinner />
            </div>
          </div>
        )}

        {loadingData ? (
          <Row>
            <Col md={4} className="mx-auto">
              <CustomSpinner />
            </Col>
          </Row>
        ) : (
          <Row className="gx-3">
            {userData?.role === "admin" ||
            userData?.team?.name?.toLowerCase() == "qc" ||
            userData?.team?.name?.toLowerCase() == "customer services" ||
            userData?._id === ticketData?.assignee?._id ||
            userData?._id === ticketData?.creator?._id ? (
              <>
                <Col md="8" className="d-flex">
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    {ticketData && (
                      <FormDetails
                        fetchTicketData={fetchTicketData}
                        ticketData={ticketData}
                        teams={teams}
                        statuses={statuses}
                        form={ticketData.form}
                        questions={ticketData.questions}
                        setDoingEditsLoading={setDoingEditsLoading}
                      />
                    )}
                  </div>

                  <div style={{ width: "75%" }}>
                    <Card
                      className="px-1 shadow-none rounded-0"
                      style={{ marginBottom: "0" }}
                    >
                      <CardBody className="shadow-none">
                        <div className="d-flex justify-content-between align-items-center pb-2">
                          <h5 className="border-bottom mb-0 text-muted">
                            {ticketData.order
                              ? `Ticket No # ${ticketData.order}`
                              : "Ticket Number"}
                          </h5>

                          {userData?._id !== ticketData?.assignee?._id &&
                          (userData.team._id === ticketData?.team?._id ||
                            userData?.role === "admin") &&
                          ticketData?.status?.category !== "solved" ? (
                            <button
                              className="btn btn-secondary py-1 px-3"
                              onClick={() => toggleTakeOwnerShipModal()}
                            >
                              Take Ownership
                            </button>
                          ) : (
                            <button
                              className="btn btn-secondary py-1 px-3 opacity-0"
                              style={{ visibility: "hidden" }}
                            >
                              Take Ownership
                            </button>
                          )}
                        </div>

                        {/* Comments Section */}
                        <Card
                          className="my-3 shadow"
                          style={{ marginBottom: "0" }}
                        >
                          <CardBody
                            className="shadow"
                            style={{
                              padding: "1rem 1.25rem",
                              height: "calc(9px + 54vh)",
                              maxHeight: "calc(9px + 54vh)",
                              overflow: "auto",
                            }}
                          >
                            {noCommentsFound ? (
                              <div className="align-self-center d-flex flex-column justify-content-center mw-50 my-auto">
                                <img
                                  className="mx-auto"
                                  style={{ height: "13.25rem" }}
                                  src={NoMessageFound}
                                />
                                <h6 className="mx-auto font-size-20">
                                  No Comments Yet!
                                </h6>
                              </div>
                            ) : loadingComments ? (
                              <CustomSpinner />
                            ) : (
                              comments?.map(comment => (
                                <SingleComment
                                  key={comment._id}
                                  comment={comment}
                                />
                              ))
                            )}

                            <div ref={commentsEndRef} />
                          </CardBody>
                        </Card>

                        {/* Input and Buttons Section */}
                        <Card className="shadow" style={{ marginBottom: "0" }}>
                          {ticketData?.status?.category === "solved" ? (
                            <CardBody
                              className="shadow position-relative"
                              style={{
                                padding: "1rem 1.25rem",
                                backgroundColor: isPrivate
                                  ? "rgb(255 221 164)"
                                  : "#FFFFFF",
                                minHeight: "142px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p className="my-auto text-center font-size-18">
                                This ticket has been closed after resolution.
                              </p>
                            </CardBody>
                          ) : userData._id === ticketData?.assignee?._id ||
                            userData._id === ticketData?.creator?._id ||
                            userData.role === "admin" ||
                            userData.team.default ? (
                            <CardBody
                              className="shadow position-relative"
                              style={{
                                padding: "1rem 1.25rem",
                                backgroundColor: isPrivate
                                  ? "rgb(255 221 164)"
                                  : "#FFFFFF",
                              }}
                            >
                              {selectedFiles.length > 0 &&
                                renderSelectedFiles()}

                              <Input
                                type="textarea"
                                name="commentinput"
                                placeholder="Write your comment here"
                                className="mb-2"
                                style={{ border: "none" }}
                                value={commentText}
                                disabled={sendingCommentLoading}
                                bsSize="lg"
                                onChange={e => setCommentText(e.target.value)}
                              />

                              <div className="d-flex justify-content-between align-items-center">
                                <div
                                  className="form-check form-switch form-switch-md"
                                  title="Control the form status."
                                >
                                  <input
                                    disabled={sendingCommentLoading}
                                    type="checkbox"
                                    className="form-check-input"
                                    id="formStatusSwitch"
                                    checked={isPrivate}
                                    onClick={() => setIsPrivate(!isPrivate)}
                                  />
                                  <label
                                    className="form-check-label font-size-14"
                                    htmlFor="formStatusSwitch"
                                  >
                                    {isPrivate ? "Internal Note" : "Public"}
                                  </label>
                                </div>
                                <div>
                                  <Button
                                    outline
                                    color="secondary"
                                    className="me-2"
                                    disabled={sendingCommentLoading}
                                    onClick={() =>
                                      document
                                        .getElementById("fileInput")
                                        .click()
                                    }
                                  >
                                    <i className="mdi mdi-attachment me-1"></i>{" "}
                                    Attach
                                  </Button>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={handleFileSelect}
                                  />

                                  <ButtonDropdown
                                    isOpen={drp_link}
                                    toggle={() => setdrp_link(!drp_link)}
                                  >
                                    <Button
                                      id="caret"
                                      color="secondary"
                                      onClick={() => handleSendComment(null)}
                                      disabled={
                                        (!commentText &&
                                          selectedFiles.length === 0) ||
                                        sendingCommentLoading
                                      }
                                    >
                                      Submit{" "}
                                      {sendingCommentLoading && (
                                        <i className="bx bx-loader bx-spin"></i>
                                      )}
                                    </Button>
                                    <DropdownToggle
                                      caret
                                      color="secondary"
                                      title="Change Ticket's Status"
                                      disabled={
                                        (!commentText &&
                                          selectedFiles.length === 0) ||
                                        sendingCommentLoading
                                      }
                                    >
                                      <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {statuses?.map(status => (
                                        <DropdownItem
                                          key={status._id}
                                          onClick={() =>
                                            handleSendComment(status._id)
                                          }
                                        >
                                          {status.name}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                </div>
                              </div>
                            </CardBody>
                          ) : (
                            <CardBody
                              className="shadow position-relative"
                              style={{
                                padding: "1rem 1.25rem",
                                backgroundColor: isPrivate
                                  ? "rgb(255 221 164)"
                                  : "#FFFFFF",
                                minHeight: "142px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p className="my-auto text-center font-size-18">
                                You do not have the necessary permissions to
                                comment on this ticket.
                              </p>
                            </CardBody>
                          )}
                        </Card>
                      </CardBody>
                    </Card>
                  </div>
                </Col>

                <Col md="4">
                  <div className="controllers d-flex justify-content-between mb-3">
                    <Button
                      style={{
                        color: "#6B7889",
                        backgroundColor:
                          selectedColumnRightSide === 1
                            ? "#fff"
                            : "transparent",
                        borderRadius: "10px",
                        boxShadow:
                          selectedColumnRightSide === 1
                            ? "0px 4px 4px 0px #E1E1E1"
                            : "none",
                        border: "none",
                        fontSize: "16px",
                      }}
                      onClick={() => setSeleectedColumnRightSide(1)}
                    >
                      Ticket Info
                    </Button>
                    <Button
                      style={{
                        color: "#6B7889",
                        backgroundColor:
                          selectedColumnRightSide === 2
                            ? "#fff"
                            : "transparent",
                        borderRadius: "10px",
                        boxShadow:
                          selectedColumnRightSide === 2
                            ? "0px 4px 4px 0px #E1E1E1"
                            : "none",
                        border: "none",
                        fontSize: "16px",
                      }}
                      onClick={() => setSeleectedColumnRightSide(2)}
                    >
                      Past Tickets
                    </Button>
                    <Button
                      style={{
                        color: "#6B7889",
                        backgroundColor:
                          selectedColumnRightSide === 3
                            ? "#fff"
                            : "transparent",
                        borderRadius: "10px",
                        boxShadow:
                          selectedColumnRightSide === 3
                            ? "0px 4px 4px 0px #E1E1E1"
                            : "none",
                        border: "none",
                        fontSize: "16px",
                      }}
                      onClick={() => setSeleectedColumnRightSide(3)}
                    >
                      Project Info
                    </Button>
                    <Button
                      style={{
                        color: "#6B7889",
                        backgroundColor:
                          selectedColumnRightSide === 4
                            ? "#fff"
                            : "transparent",
                        borderRadius: "10px",
                        boxShadow:
                          selectedColumnRightSide === 4
                            ? "0px 4px 4px 0px #E1E1E1"
                            : "none",
                        border: "none",
                        fontSize: "16px",
                      }}
                      onClick={() => setSeleectedColumnRightSide(4)}
                    >
                      Ticket Log
                    </Button>
                  </div>

                  {selectedColumnRightSide === 1 && (
                    <EditTicketInfo
                      ticketData={ticketData}
                      fetchTicketData={fetchTicketData}
                      doingEditsLoading={doingEditsLoading}
                      setDoingEditsLoading={setDoingEditsLoading}
                    />
                  )}
                  {selectedColumnRightSide === 2 && (
                    <PastTickets
                      ticketPastTicketsSocket={ticketPastTicketsSocket}
                    />
                  )}
                  {selectedColumnRightSide === 3 && <TicketProjectInfo />}
                  {selectedColumnRightSide === 4 && (
                    <TicketLog ticketLogFromSocket={ticketLogFromSocket} />
                  )}
                </Col>
              </>
            ) : (
              <div className="p-4">
                <Alert color="danger">
                  You are not allowed to see this ticket!
                </Alert>
              </div>
            )}
          </Row>
        )}
      </Container>

      <Modal
        // className="chat-tools-change-name"
        isOpen={takeOwnerShipModal}
        toggle={toggleTakeOwnerShipModal}
        centered
      >
        <ModalHeader toggle={toggleTakeOwnerShipModal}>
          Take Ownership
        </ModalHeader>
        <ModalBody>Confirm Take ownership</ModalBody>
        <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
          <Button
            color="secondary"
            outline
            onClick={toggleTakeOwnerShipModal}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              toggleTakeOwnerShipModal()
              tackeTiccketOwnership()
            }}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Confirm
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ViewTicket
