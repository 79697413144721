import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Col, Container, Row, Card, CardBody, Button, Alert } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import configs from "config"
import { useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"
import CustomSpinner from "components/Common/CustomSpinner"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

const { Url, apiVersion } = configs.client
import "./TicketForms/TicketFormsList.css"
import { generateUserLocalDateFromTimestamp } from "helpers/helper_functions"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TicketFields = () => {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const [fields, setFields] = useState([])
  const [modal, setModal] = useState(false)
  const [loadingFields, setLoadingFields] = useState(true)
  const [noFieldsFound, setNoFieldsFound] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const toggle = () => {
    setModal(!modal)
  }

  const fetchFieldsData = () => {
    axios
      .get(`${Url}/${apiVersion}/fields`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setFields(res.data.data.fields)

        if (res.data.data.fields.length === 0) {
          setNoFieldsFound(true)
        } else {
          setNoFieldsFound(false)
        }
        setLoadingFields(false)
      })
      .catch(err => {
        setLoadingFields(false)

        if (
          err.response.status == 401 ||
          err.response.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          toast.error("Failed to fetch fields data. Please try again later.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          console.log(err)
        }
      })
  }

  useEffect(() => {
    fetchFieldsData()
  }, [])

  const handleEdit = id => {
    navigate(`/ticket/fields/${id}`)
  }

  const handleAddNew = () => {
    navigate("/ticket/fields/new")
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {userData.role === "admin" ? (
        <Container fluid className="p-4">
          <Breadcrumbs title="Tickets" breadcrumbItem="Fields" />

          <Row className="mt-2">
            {loadingFields ? (
              <Col md={4} className="mx-auto">
                <CustomSpinner />
              </Col>
            ) : (
              <Col lg="12">
                <div className="d-flex mb-2 justify-content-end">
                  <Button color="secondary py-1 px-2" onClick={handleAddNew}>
                    <i className="bx bx-plus"></i> Add Field
                  </Button>
                </div>

                <Card className="rounded-3">
                  <CardBody>
                    <div
                      className="table-responsive mt-3"
                      style={{ maxHeight: "74vh", overflowY: "auto" }}
                    >
                      <table className="table table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Active Forms</th>
                            <th>Status</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        {noFieldsFound ? (
                          <td
                            className="text-center py-4 font-size-16"
                            colSpan={5}
                          >
                            No fields are found
                          </td>
                        ) : (
                          <tbody>
                            {fields.map(field => (
                              <tr
                                key={field.id}
                                className="hover-form-row"
                                onClick={() => handleEdit(field._id)}
                              >
                                <td>{field.name}</td>
                                <td>{field.type?.name || "N/A"}</td>
                                <td>{field.forms.length}</td>

                                <td>
                                  {field.status === "active" ? (
                                    <span className="badge-soft-success badge bg-secondary rounded-pill py-2 px-3">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge-soft-dark badge bg-secondary rounded-pill py-2 px-3">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {generateUserLocalDateFromTimestamp(
                                    field.createdAt
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      ) : (
        <Alert color="danger">You are not allowed to be here!</Alert>
      )}
    </React.Fragment>
  )
}

export default withRouter(TicketFields)
