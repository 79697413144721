import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import configs from "config"
const { Url, apiVersion } = configs.client
import { useParams } from "react-router-dom"
import axios from "axios"
import moment from "moment"
import CustomSpinner from "components/Common/CustomSpinner"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function TicketLog({ ticketLogFromSocket }) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login?.userAccess,
    authorized: state.Login.authorized,
  }))

  const [ticketLogData, setTicketLogData] = useState(ticketLogFromSocket || [])
  const [loadingTicketLog, setLoadingTicketLog] = useState(true)

  const params = useParams()
  const dispatch = useDispatch()

  const fetchTicketLog = () => {
    axios
      .get(`${Url}/${apiVersion}/tickets/${params.ticketId}/ticket-logs`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setLoadingTicketLog(false)
        setTicketLogData(res.data.data.ticketLogs)
      })
      .catch(err => {
        setLoadingTicketLog(false)

        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          toast.error("Failed to fetch ticket logs. Please try again.")
          console.log(err)
        }
      })
  }

  useEffect(() => {
    fetchTicketLog()
  }, [])

  // Use useEffect to update the state when ticketLogFromSocket changes
  useEffect(() => {
    if (ticketLogFromSocket) {
      setTicketLogData(ticketLogFromSocket)
    }
  }, [ticketLogFromSocket])

  const formatLog = log => {
    const userFullName = `${log.user?.firstName} ${log.user?.lastName}`
    const formattedDate = moment(log.createdAt).format("DD MMMM YYYY hh:mm A")

    switch (log.log) {
      case "create":
        return {
          title: "New Ticket",
          description: `${userFullName} created the ticket`,
          date: formattedDate,
        }
      case "assign":
        return {
          title: "Assign Ticket",
          description: `${userFullName} assigned the ticket to ${log.assignee.firstName} ${log.assignee.lastName}`,
          date: formattedDate,
        }
      case "transfer":
        return {
          title: "Transfer Ticket",
          description: `${userFullName} transferred the ticket from ${log.transfer?.from?.user?.firstName} ${log.transfer?.from?.user?.lastName} to ${log.transfer?.to?.user.firstName} ${log.transfer.to?.user?.lastName}`,
          date: formattedDate,
        }
      case "close":
        return {
          title: "Close Ticket",
          description: `${userFullName} closed the ticket`,
          date: formattedDate,
        }
      case "priority":
        return {
          title: "Update Ticket Priority",
          description: `${userFullName} updated the ticket priority to ${log.priority}`,
          date: formattedDate,
        }
      case "status":
        return {
          title: "Update Ticket Status",
          description: `${userFullName} updated the ticket status to ${log.status.name}`,
          date: formattedDate,
        }
      case "client":
        return {
          title: "Update Client Data",
          description: `${userFullName} updated client data`,
          date: formattedDate,
        }
      case "comment":
        return {
          title: "New Comment",
          description: `${userFullName} added a new comment`,
          date: formattedDate,
        }
      case "takeOwnership":
        return {
          title: "Ticket Ownership",
          description: `${userFullName} took ownership of the ticket`,
          date: formattedDate,
        }
      case "clientComment":
        return {
          title: "New Client Comment",
          description: `Client added a new comment`,
          date: formattedDate,
        }
      default:
        return null
    }
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div
      style={{
        height: "calc(-6px + 77vh)",
        maxHeight: "calc(-6px + 77vh)",
        overflow: "auto",
        padding: "16px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <ToastContainer />

      {loadingTicketLog ? (
        <CustomSpinner />
      ) : (
        ticketLogData.map((log, index) => {
          const formattedLog = formatLog(log)
          return (
            formattedLog && (
              <div key={index} className="mb-4">
                <div className="d-flex justify-content-between">
                  <h6
                    className="text-black mb-1"
                    style={{ fontWeight: "bold" }}
                  >
                    {formattedLog.title}
                  </h6>
                  <small className="text-muted">{formattedLog.date}</small>
                </div>
                <p className="mb-1">{formattedLog.description}</p>
                <hr className="my-3" />
              </div>
            )
          )
        })
      )}
    </div>
  )
}

export default TicketLog
