import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import Select from "react-select"
import { PhoneInput } from "react-international-phone"
import "react-international-phone/style.css"

import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { useDispatch } from "react-redux"
import { unAuthUser } from "store/actions"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function EditUser({
  toggle,
  isOpen,
  user,
  selectedTeam,
  setSelectedTeam,
  selectedRole,
  setSelectedRole,
  fetchFilteredUsers,
  setShowEditToast,
}) {
  const [errorMessage, setErrorMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userSecretValue, setUserSecretValue] = useState(user?.secret || false)

  const dispatch = useDispatch()

  // users role

  const roleOptions = [
    { value: "user", label: "User" },
    { value: "admin", label: "Admin" },
  ]
  const handleRoleChange = selectedOption => {
    setSelectedRole(selectedOption)
    setFieldValue("role", selectedOption.value)
  }
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const EditUserSchema = Yup.object().shape({
    firstName: Yup.string()
      // .min(2, "Too Short!")
      // .max(15, "Too Long!")
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    lastName: Yup.string()
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),

    email: Yup.string()
      .email("Invalid ")
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .nullable()
      .matches(/^(?!\s+$).*/, "invalid"),

    passwordConfirm: Yup.string()
      .nullable()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .matches(/^(?!\s+$).*/, "invalid"),

    Phone: Yup.string()
      .required("required")
      .min(10, "phone must be at least 10 characters"),
    tasks: Yup.array().min(1, "required"),
    // tasks: Yup.array().required( "required"),
  })

  useEffect(() => {
    if (user) {
      const selectedRole = roleOptions.find(role => role.value === user.role)
      setSelectedRole(selectedRole)
      setFieldValue("role", user?.role || "")
      setFieldValue("firstName", user.firstName || "")
      setFieldValue("lastName", user.lastName || "")
      setFieldValue("email", user?.email || "")
      setFieldValue("Phone", user.phone?.replace("+", "") || "")
      const selectedStatus = StatusOptions.find(
        status => status.value === user.status
      )
      setSelectedStatus(selectedStatus)
      setFieldValue("status", user?.status || "")
      setFieldValue("team", user?.team || "")
    }
  }, [user])

  const [selectedStatus, setSelectedStatus] = useState(null)
  const StatusOptions = [
    { value: "Online", label: "Online" },
    { value: "Offline", label: "Offline" },
    { value: "Service hours", label: "Service hours" },
    { value: "Away", label: "Away" },
  ]
  const handleStatusChange = selectedOption => {
    setSelectedStatus(selectedOption)
    setFieldValue("status", selectedOption.value)
  }

  // formik
  const {
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      role: user?.role || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      password: "",
      passwordConfirm: "",
      phone: user?.phone?.replace("+", "") || "",
      // team: user?.team || "",
      status: user?.status || "",
      ticketRequests: user?.ticketRequests || [],
      tasks: user?.tasks || [],
      secret: userSecretValue,
    },
    validationSchema: EditUserSchema,
    onSubmit: values => {
      setIsLoading(true)
      setErrorMessage()

      let dataToSend = {
        ticketRequests: values?.ticketRequests || [],
        tasks: values?.tasks || [],
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        role: values.role,
        status: values.status,
        phone: values.phone,
        ...(values.password
          ? {
              password: values.password,
              passwordConfirm: values.passwordConfirm,
            }
          : {}),
      }

      if (userData.superAdmin) {
        dataToSend.secret = userSecretValue
      }

      if (dataToSend)
        axios
          .patch(`${Url}/${apiVersion}/users/${user.id}`, dataToSend, {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              Authorization: `Bearer ${access}`,
            },
          })
          .then(res => {
            if (res.status === 200) {
              toggle()
              setIsLoading(false)

              fetchFilteredUsers()
              toast.success("User updated successfully!")
            } else {
              console.error("Unexpected status code:", res.status, res.data)
            }
          })
          .catch(error => {
            setIsLoading(false)

            if (
              error.response.status === 401 ||
              error.response.status === 0 ||
              !error.response.status
            ) {
              dispatch(unAuthUser())
            } else {
              console.log(error)
              toast.error("An unexpected error occurred")
            }
          })
    },
  })
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "gray" : "white",
      backgroundColor: state.isFocused ? "#EBEDF0" : "white",
      color: state.isSelected ? "gray" : "black",
    }),
    menu: provided => ({
      ...provided,
      zIndex: 10000,
      height: "1rem",
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: "10rem",
      overflowY: "auto",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }),
  }

  //ticket requests
  const ticketRequestsOptions = [
    { value: "RD0", label: "RD0" },
    { value: "Edit RD0", label: "Edit RD0" },
    { value: "Missing Data", label: "Missing Data" },
    { value: "Design Review", label: "Design Review" },
    { value: "RD6", label: "RD6" },
    { value: "RD7", label: "RD7" },
    { value: "Finance", label: "Finance" },
    { value: "Inspection", label: "Inspection" },
    { value: "MALATH Issue", label: "MALATH Issue" },
    { value: "MALATH Complaint", label: "MALATH Complaint" },
    { value: "Other", label: "Other" },
  ]
  const [selectedTicket, setSelectedTicket] = useState([])

  useEffect(() => {
    if (user?.ticketRequests) {
      const defaultValues = user.ticketRequests.map(value => ({
        value,
        label:
          ticketRequestsOptions.find(option => option.value === value)?.label ||
          value,
      }))
      setFieldValue("ticketRequests", user?.ticketRequests)
      setSelectedTicket(defaultValues)
    }
  }, [user])

  //tasks:
  const [selectedTasks, setSelectedTasks] = useState(null)
  const tasksOptions = [
    { value: "tickets", label: "tickets" },
    { value: "messages", label: "messages" },
  ]
  const handleTasksChange = selectedOptions => {
    setSelectedTasks(selectedOptions)
    const selectedTasks = selectedOptions
      ? selectedOptions?.map(option => option.value)
      : []
    setFieldValue("tasks", selectedTasks)
  }

  useEffect(() => {
    if (user?.tasks) {
      const defaultValues = user.tasks?.map(value => ({
        value,
        label:
          tasksOptions.find(option => option.value === value)?.label || value,
      }))
      setFieldValue("tasks", user?.tasks)
      setSelectedTasks(defaultValues)
    }
  }, [user])

  const handleTicketChange = selectedOptions => {
    setSelectedTicket(selectedOptions)
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setFieldValue("ticketRequests", selectedValues)
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <ToastContainer />

      <ModalHeader toggle={toggle}>Edit User</ModalHeader>
      <ModalBody>
        {errorMessage && (
          <div className="" aria-live="polite" aria-atomic="true">
            <div className="">
              <Toast isOpen={errorMessage} fade={true}>
                <ToastBody className="delete-message-bg">
                  <h5 className="m-0 mb-2">Error</h5>
                  <p className="m-0">{errorMessage}</p>
                </ToastBody>
              </Toast>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1">First Name</Label>
                <Input
                  className=" p-5px m-0 "
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //   placeholder="FirstName"
                  type="text"
                />
                {errors.firstName &&
                  touched.firstName &&
                  values?.firstName?.trim() === "" && (
                    <div className="error mb-0">{errors.firstName}</div>
                  )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1">Last Name</Label>
                <Input
                  className=" p-5px m-0"
                  id="lastName"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  //   placeholder="LastName"
                  type="text"
                />
                {errors.lastName &&
                  touched.lastName &&
                  values?.lastName?.trim() === "" && (
                    <div className="error mb-0">{errors.lastName}</div>
                  )}
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="m-0 m-mb-2">
            <Label className="mb-1">Email</Label>
            <Input
              className=" p-5px m-0"
              id="email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              // placeholder="Email"
              type="email"
            />
            {errors.email && touched.email && (
              <div className="error mb-0">{errors.email}</div>
            )}
          </FormGroup>
          <FormGroup className="m-0 m-mb-2 phone-add">
            <Label className="mb-1">WhatsApp Number</Label>
            <PhoneInput
              value={user?.phone?.replace("+", "")}
              onChange={phone =>
                setFieldValue("phone", phone?.replace("+", ""))
              }
              onBlur={handleBlur}
              id="phone"
              name="phone"
              defaultCountry="sa"
              // inputProps={{
              //   value: values.phone ? values.phone?.replace("+", "") : "",
              // }}
              inputProps={{
                value: user?.phone ? values.phone?.replace("+", "") : "",
              }}
            />
            {errors.phone && touched.phone && (
              <div className="error mb-0">{errors.phone}</div>
            )}
          </FormGroup>
          <FormGroup className="m-0 m-mb-2">
            <Label className="mb-1">Password</Label>
            <Input
              className=" p-5px m-0"
              id="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              type="password"
            />
            {errors.password && touched.password && (
              <div className="error mb-0">{errors.password}</div>
            )}
          </FormGroup>
          <FormGroup className="m-0 m-mb-2">
            <Label className="mb-1">Confirm Password</Label>
            <Input
              className=" p-5px m-0"
              id="passwordConfirm"
              name="passwordConfirm"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.passwordConfirm}
              required={values.password}
            />
            {errors.passwordConfirm && touched.passwordConfirm && (
              <div className="error mb-0">{errors.passwordConfirm}</div>
            )}
            {touched.passwordConfirm &&
              values.password &&
              !values.passwordConfirm && <div className="error">required</div>}
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-0">Status</Label>
                <Select
                  className=" p-5px m-0"
                  id="user-status"
                  name="status"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={StatusOptions}
                  styles={customStyles}
                ></Select>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-0">User Role</Label>
                <Select
                  className=" p-5px m-0"
                  id="user-role"
                  name="role"
                  value={selectedRole}
                  options={roleOptions}
                  onChange={handleRoleChange}
                  styles={customStyles}
                ></Select>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-0">ticket Requests</Label>
                <Select
                  isMulti
                  className="p-5px m-0"
                  id="ticketRequests"
                  name="ticketRequests"
                  value={selectedTicket}
                  options={ticketRequestsOptions}
                  onChange={handleTicketChange}
                  styles={customStyles}
                ></Select>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-0">Tasks</Label>
                <Select
                  isMulti
                  className="p-5px m-0"
                  id="tasks"
                  name="tasks"
                  value={selectedTasks}
                  options={tasksOptions}
                  onChange={handleTasksChange}
                  styles={customStyles}
                ></Select>
              </FormGroup>
              {errors.tasks && touched.tasks && (
                <div className="error mb-0">{errors.tasks}</div>
              )}
            </Col>
          </Row>

          {userData.superAdmin && (
            <FormGroup className="m-0 m-mb-2">
              <Label for="secret" className="mb-1 me-2 font">
                User is authorized to manage secret sessions.
              </Label>
              <Input
                type="checkbox"
                id="secret"
                name="secret"
                checked={userSecretValue}
                onClick={e => setUserSecretValue(!userSecretValue)}
              />
            </FormGroup>
          )}

          {isLoading && (
            <div className="loading-overlay">
              <Spinner />
            </div>
          )}
          <FormGroup className=" d-flex justify-content-end gap-3 edit-popup mb-0">
            <Button type="submit" color="secondary" className="py-1 px-3">
              Edit
            </Button>
            <Button
              onClick={toggle}
              color="secondary"
              outline
              className="py-1 px-3"
            >
              Cancel
            </Button>
          </FormGroup>
        </form>
      </ModalBody>
    </Modal>
  )
}
