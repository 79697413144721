import React, { useState } from "react"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"

import axios from "axios"
import { useParams } from "react-router-dom"

import { useSelector } from "react-redux"

import configs from "../../../config"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
const { Url, apiVersion } = configs.client

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const VisitReportModal = ({ report, sendReportToggle }) => {
  const params = useParams()
  const dispatch = useDispatch()

  const [modal, setmodal] = useState(true)
  const toggle = () => {
    sendReportToggle()
  }

  const onClose = () => {
    sendReportToggle()
  }

  let { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const submitHandler = () => {
    const messageText = `السادة الأفاضل،\n\nتحية طيبة وبعد،\n\nيرجى العلم بأنه تمت زيارة المشروع رقم مرجعي (${localStorage.getItem(
      "ref"
    )}) بتاريخ: ${report.StageDate} من قبل ممثلنا المهندس: ${
      report.InspectorName
    } لمرحلة: ${
      report.StageName
    }\n\nوقد تم إصدار تقرير فحص فني من قبل شركة سي بي في العربية لهذه المرحلة.\n\nيرجى الاطلاع على الرابط أدناه:\n${
      report.Link
    }\n\nكما نرجو منكم تقييم الزيارة التي تمت من قبل الفاحص الفني لغرض تحسين الخدمة:\n${
      report.Care
    }
`

    axios
      .post(
        `${Url}/${apiVersion}/chats/${params.id}/messages`,
        { type: "text", text: messageText },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        toast.success("Report sent successfully!")
      })
      .catch(err => {
        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to send report.")
        }
      })

    onClose()
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md" centered>
      <ToastContainer />

      <ModalHeader>
        Send Report Visit
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>

      <ModalBody className="px-4 py-3 text-center">
        <p className="text-muted font-size-16 mb-4">
          Are you sure you want to send this Report?
        </p>

        <div className="hstack gap-2 justify-content-center mb-0">
          <Button
            color="secondary"
            outline
            style={{ padding: "0.1875rem 0.875rem" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            style={{ padding: "0.1875rem 0.875rem" }}
            onClick={submitHandler}
          >
            Send Now
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default VisitReportModal
