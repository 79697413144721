import axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import configs from "config"
import { useParams } from "react-router-dom"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import SubmitButtonComponent from "./SubmitButtonComponent"
const { Url, apiVersion } = configs.client
import Select from "react-select"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function EditTicketInfo({
  ticketData,
  fetchTicketData,
  doingEditsLoading,
  setDoingEditsLoading,
}) {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const params = useParams()
  const dispatch = useDispatch()

  const [ticketInfoModal, setTicketInfoModal] = useState(false)
  const toggleTicketInfoModal = () => setTicketInfoModal(!ticketInfoModal)

  const [userInfoModal, setUserInfoModal] = useState(false)
  const toggleUserInfoModal = () => setUserInfoModal(!userInfoModal)

  const [clientInfoModal, setClientInfoModal] = useState(false)
  const toggleClientInfoModal = () => setClientInfoModal(!clientInfoModal)

  const [statuses, setStatuses] = useState([])
  const [statusesData, setStatusesData] = useState([])
  const [categories, setCategories] = useState([])
  const [teams, setTeams] = useState([])
  const [users, setUsers] = useState([])
  const [selectedTeamId, setSelectedTeamId] = useState("")
  const [selectedUserId, setSelectedUserId] = useState("")
  const [defaultTeamId, setDefaultTeamId] = useState("")
  useState(false)
  const [requestTypes, setRequestTypes] = useState([])
  const [requireTeamMember, setRequireTeamMember] = useState(false)

  const [showTeamError, setShowTeamError] = useState(false)
  const [clientInfoErrors, setClientInfoErrors] = useState({
    name: "",
    number: "",
    email: "",
  })

  const [formEditTicketInfo, setFormEditTicketInfo] = useState({
    priority: ticketData?.priority,
    statusSelect: ticketData?.status?._id,
    category: ticketData?.category?._id,
    requestOfType: ticketData?.requestType || "",
    natureOfRequest: ticketData?.requestNature || "",
  })

  const [formEditClientInfo, setFormEditClientInfo] = useState({
    name: ticketData?.client?.name || "",
    number: ticketData?.client?.number || "",
    email: ticketData?.client?.email || "",
  })

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Highlight focused option, otherwise white
      color: state.isSelected ? "#333" : "#000", // Change text color if selected
    }),
    control: provided => ({
      ...provided,
      borderColor: "#ddd", // Custom border color for the select input
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: "#fff", // Make sure the dropdown menu is white
    }),
  }

  function isUserSupervisor(userData, teamsData, supervisorTeamId) {
    const superVisorTeam = teams.find(team => team._id === supervisorTeamId)

    if (superVisorTeam && superVisorTeam.supervisor._id === userData._id) {
      return true
    } else {
      return false
    }
  }

  const fetchTicketStatuses = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketStatuses?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const statusOptions = res.data.data.statuses.map(status => ({
          value: status._id,
          label: status.name,
        }))
        setStatuses(statusOptions)
        setStatusesData(res.data.data.statuses)
      })
      .catch(err => {
        handleError(err, "Failed to fetch ticket statuses")
      })
  }

  const fetchTicketCategories = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketCategories?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const categoryOptions = res.data.data.categories.map(category => ({
          value: category._id,
          label: category.name,
        }))
        setCategories(categoryOptions)
      })
      .catch(err => {
        handleError(err, "Failed to fetch ticket categories")
      })
  }

  const fetchTeams = () => {
    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const teamOptions = res.data.data.teams.map(team => ({
          value: team._id,
          label: team.name,
        }))
        setTeams(teamOptions)
        setDefaultTeamId(res.data.data.defaultTeam._id)
      })
      .catch(err => {
        handleError(err, "Failed to fetch teams")
      })
  }

  const fetchUsers = () => {
    axios
      .get(
        `${Url}/${apiVersion}/users?type=ticketCreate&teamID=${selectedTeamId}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        const userOptions = res.data.data.users.map(user => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
        }))
        setUsers(userOptions)
      })
      .catch(err => {
        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const handleEditTicketInfoSubmit = () => {
    const requestBody = {}

    if (formEditTicketInfo.priority !== ",,,") {
      requestBody.priority = formEditTicketInfo.priority
    }
    if (formEditTicketInfo.statusSelect !== ",,,") {
      requestBody.status = formEditTicketInfo.statusSelect
    }
    if (formEditTicketInfo.category !== ",,,") {
      requestBody.category = formEditTicketInfo.category
    }
    if (formEditTicketInfo.requestType !== ",,,") {
      requestBody.requestType = formEditTicketInfo.requestOfType
    }
    if (formEditTicketInfo.natureOfRequest !== ",,,") {
      requestBody.requestNature = formEditTicketInfo.natureOfRequest
    }

    toggleTicketInfoModal()
    setDoingEditsLoading(true)

    axios
      .patch(
        `${Url}/${apiVersion}/tickets/${params.ticketId}/info`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        console.log("Ticket updated successfully", res.data)
        fetchTicketData()
        setDoingEditsLoading(false)
      })
      .catch(err => {
        setDoingEditsLoading(false)
        if (
          err.response?.status === 401 ||
          err.response?.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const handleEditUserInfoSubmit = statusId => {
    if (!selectedTeamId) {
      setShowTeamError(true)
      return
    }

    const requestBody = {}

    if (selectedTeamId) {
      requestBody.team = selectedTeamId
    }
    if (selectedUserId) {
      requestBody.assignee = selectedUserId
    }
    if (statusId) {
      requestBody.status = statusId
    }

    if (!Object.keys(requestBody).length) {
      setShowTeamError(true)
      return
    }

    toggleUserInfoModal()
    setDoingEditsLoading(true)

    axios
      .patch(
        `${Url}/${apiVersion}/tickets/${params.ticketId}/transfer`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        console.log("Ticket updated successfully", res.data)
        setDoingEditsLoading(false)
        fetchTicketData()
      })
      .catch(err => {
        setDoingEditsLoading(false)

        if (
          err.response?.status === 401 ||
          err.response?.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const handleEditClientInfoSubmit = () => {
    const errors = {}

    // Check if either phone number or email is provided, but not require both.
    if (!formEditClientInfo.name) {
      errors.name = "Required"
    }

    if (!formEditClientInfo.number && !formEditClientInfo.email) {
      errors.number = "Required"
      errors.email = "Required"
    } else if (
      formEditClientInfo.number &&
      formEditClientInfo.number.length < 11
    ) {
      errors.number = "Phone number must be at least 11 digits"
    }

    // Set validation errors for both fields, only if none is provided
    setClientInfoErrors(errors)

    if (Object.keys(errors).length > 0) {
      return
    }

    const requestBody = {
      client: formEditClientInfo,
    }

    toggleClientInfoModal()
    setDoingEditsLoading(true)

    axios
      .patch(
        `${Url}/${apiVersion}/tickets/${params.ticketId}/client`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        // console.log("Client details updated successfully", res.data)
        setDoingEditsLoading(false)
        fetchTicketData()
      })
      .catch(err => {
        setDoingEditsLoading(false)

        if (
          err.response?.status === 401 ||
          err.response?.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const handleInputChange = selectedOption => {
    const { name, value } = selectedOption
    setFormEditTicketInfo({
      ...formEditTicketInfo,
      [name]: value,
    })
  }

  const handleClientInputChange = selectedOption => {
    const { name, value } = selectedOption
    setFormEditClientInfo({
      ...formEditClientInfo,
      [name]: value,
    })
  }

  const handleTeamChange = selectedTeam => {
    setSelectedTeamId(selectedTeam.value)
    setRequireTeamMember(false) // Reset

    // Check if the selected team matches the ticket team
    if (selectedTeam.value === ticketData.team._id) {
      setRequireTeamMember(true) // Enforce that the user must choose a team member
    } else {
      setRequireTeamMember(false)
    }

    fetchUsersForTeam(selectedTeam.value) // Fetch users for the selected team
    setShowTeamError(false) // Reset error state if a team is selected
  }

  // Handle the selection of a new user (assignee)
  const handleUserChange = selectedUser => {
    setSelectedUserId(selectedUser.value)
  }

  const fetchUsersForTeam = teamId => {
    if (!teamId) return

    axios
      .get(`${Url}/${apiVersion}/users?type=ticketCreate&teamID=${teamId}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        // Filter out the current assignee from the users list
        const filteredUsers = res.data.data.users.filter(
          user => user._id !== ticketData.assignee._id
        )
        const userOptions = filteredUsers.map(user => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
        }))
        setUsers(userOptions)
      })
      .catch(err => {
        handleError(err, "Failed to fetch users for the team")
      })
  }

  useEffect(() => {
    fetchTicketStatuses()
    fetchTicketCategories()
    fetchTeams()
  }, [])

  useEffect(() => {
    if (selectedTeamId) {
      fetchUsersForTeam(selectedTeamId)
      setSelectedUserId("")
    }
  }, [selectedTeamId])

  const canEditTicket = () => {
    const isAdmin = userData?.role === "admin"
    const isCreator = userData?._id === ticketData?.creator?._id
    const isAssignee = userData?._id === ticketData?.assignee?._id
    const isSupervisor = isUserSupervisor(
      userData,
      teams,
      ticketData?.team?._id
    )
    const isUserDefaultTeamSupervisor = isUserSupervisor(
      userData,
      teams,
      defaultTeamId
    )
    const isSolved = ticketData?.status?.category === "solved"

    if (isSolved) {
      return isAdmin || isCreator || isSupervisor || isUserDefaultTeamSupervisor
    } else {
      return isAdmin || isAssignee || isCreator || isSupervisor
    }
  }

  const handleError = (err, defaultMessage) => {
    if (
      err.response?.status === 401 ||
      err.response?.status === 0 ||
      !err.response.status
    ) {
      dispatch(unAuthUser())
      toast.error("Unauthorized. Please log in again.")
    } else {
      console.log("Error:", err)
      toast.error(defaultMessage || "An error occurred.")
    }
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Card
      style={{
        height: "calc(-6px + 77vh)",
        maxHeight: "calc(-6px + 77vh)",
        overflow: "auto",
      }}
      className="p-3"
    >
      <ToastContainer />

      <CardBody className="shadow-sm rounded-3 mb-2">
        <div className="mb-3 d-flex justify-content-between">
          <h5 className="mb-0 font-size-18">Ticket Info</h5>

          {/* {(userData?.role === "admin" ||
              userData?._id === ticketData?.assignee?._id ||
              userData?._id === ticketData?.creator?._id ||
              isUserSupervisor(userData, teams, ticketData?.team?._id)) &&
            ticketData?.status?.category !== "solved" ? (
              <i
                className="bx bx-edit-alt bx-sm"
                style={{ cursor: "pointer" }}
                onClick={() => toggleTicketInfoModal()}
              ></i>
            ) : (
              <div></div>
            )} */}

          {canEditTicket() ? (
            <i
              className="bx bx-edit-alt bx-sm"
              style={{ cursor: "pointer" }}
              onClick={() => toggleTicketInfoModal()}
            ></i>
          ) : (
            <div></div>
          )}
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Priority <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.priority == "Low" && (
              <Badge pill className="badge-soft-primary ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Low
              </Badge>
            )}

            {ticketData?.priority == "Normal" && (
              <Badge pill className="badge-soft-success ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Normal
              </Badge>
            )}

            {ticketData?.priority == "High" && (
              <Badge pill className="badge-soft-warning ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> High
              </Badge>
            )}

            {ticketData?.priority == "Urgent" && (
              <Badge pill className="badge-soft-danger ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Urgent
              </Badge>
            )}

            {(!ticketData?.priority ||
              (ticketData?.priority !== "Low" &&
                ticketData?.priority !== "Normal" &&
                ticketData?.priority !== "High" &&
                ticketData?.priority !== "Urgent")) && (
              <Badge pill className="badge-soft-dark ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span>{" "}
                {ticketData?.priority || "Unknown"}
              </Badge>
            )}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Status <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.status?.category?.toLowerCase() == "pending" && (
              <Badge pill className="badge-soft-warning ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Pending
              </Badge>
            )}

            {ticketData?.status?.category?.toLowerCase() == "solved" && (
              <Badge pill className="badge-soft-secondary ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Solved
              </Badge>
            )}

            {ticketData?.status?.category?.toLowerCase() == "new" && (
              <Badge pill className="badge-soft-danger ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> New
              </Badge>
            )}

            {ticketData?.status?.category?.toLowerCase() == "open" && (
              <Badge pill className="badge-soft-success ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Open
              </Badge>
            )}

            {(!ticketData?.status?.category ||
              (ticketData?.status?.category?.toLowerCase() !== "new" &&
                ticketData?.status?.category?.toLowerCase() !== "open" &&
                ticketData?.status?.category?.toLowerCase() !== "solved" &&
                ticketData?.status?.category?.toLowerCase() !== "pending")) && (
              <Badge pill className="badge-soft-dark ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span>{" "}
                {ticketData?.status?.category || "Unknown"}
              </Badge>
            )}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Reference No <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.refNo ? ticketData?.refNo : "Enter a ref number"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Category <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.category?.name
              ? ticketData?.category?.name
              : "Choose a category name"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Type of request <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.requestType
              ? ticketData?.requestType
              : "Choose request type"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Nature of request <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1 mb-0">
            {ticketData?.requestNature
              ? ticketData?.requestNature
              : "Choose the request type"}
          </p>
        </div>
      </CardBody>

      <CardBody className="shadow-sm rounded-3 mb-2">
        <div className="mb-3 d-flex justify-content-between">
          <h5 className="mb-0 font-size-18">User Info</h5>

          {(userData?.role === "admin" ||
            userData?._id === ticketData?.assignee?._id ||
            userData?._id === ticketData?.creator?._id ||
            isUserSupervisor(userData, teams, ticketData?.team?._id)) &&
          ticketData?.status?.category !== "solved" ? (
            <i
              className="bx bx-edit-alt bx-sm"
              style={{ cursor: "pointer" }}
              onClick={() => toggleUserInfoModal()}
            ></i>
          ) : (
            <div></div>
          )}
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Requested by <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.creator?.firstName || ticketData?.creator?.lastName
              ? `${ticketData?.creator?.firstName} ${ticketData?.creator?.lastName}`
              : "Choose an creator name"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Assignee team <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.team?.name
              ? ticketData?.team?.name
              : "Choose a team name"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Assignee user <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1 mb-0">
            {ticketData?.assignee?.firstName || ticketData?.assignee?.lastName
              ? `${ticketData?.assignee?.firstName} ${ticketData?.assignee?.lastName}`
              : "Choose an assignee name"}
          </p>
        </div>
      </CardBody>

      {/* test */}
      <CardBody className="shadow-sm rounded-3">
        <div className="mb-3 d-flex justify-content-between">
          <h5 className="mb-0 font-size-18">Client Details</h5>
          {(userData?.role === "admin" ||
            userData?._id === ticketData?.assignee?._id ||
            userData?._id === ticketData?.creator?._id ||
            isUserSupervisor(userData, teams, ticketData?.team?._id)) &&
          ticketData?.status?.category !== "solved" ? (
            <i
              className="bx bx-edit-alt bx-sm"
              style={{ cursor: "pointer" }}
              onClick={() => toggleClientInfoModal()}
            ></i>
          ) : (
            <div></div>
          )}
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Name <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.client?.name
              ? ticketData?.client?.name
              : "Enter client name"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Phone Number <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.client?.number
              ? ticketData?.client?.number
              : "Enter phone number"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted font-size-16">
            Email <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1 mb-0">
            {ticketData?.client?.email
              ? ticketData?.client?.email
              : "Enter email address"}
          </p>
        </div>
      </CardBody>

      <CardBody className="shadow-sm rounded-3">
        <div className="mb-3 d-flex justify-content-start">
          <h5 className="mb-0 font-size-18">Feedback</h5>
        </div>

        {ticketData?.feedback ? (
          <div className="p-3 border rounded-3">
            <div className="mb-2">
              <div className="d-flex justify-content-between align-content-center">
                <h6 className="mb-0 font-size-16 align-self-center">
                  {ticketData.client.name}
                </h6>

                <p className="mb-0">
                  {ticketData.rating === "Positive" ? (
                    <i
                      title="Positive feedback"
                      className="mdi mdi-thumb-up text-success fa-2x"
                    ></i>
                  ) : ticketData.rating === "Negative" ? (
                    <i
                      title="Negative feedback"
                      className="mdi mdi-thumb-down text-danger fa-2x"
                    ></i>
                  ) : (
                    <span
                      role="img"
                      aria-label="Neutral"
                      style={{ fontSize: "2rem" }}
                    >
                      <svg
                        width="24"
                        height="36"
                        viewBox="0 0 24 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.476246 21.75C0.476246 16.525 0.419996 11.075 0.419996 5.85001C0.419996 4.27501 1.695 3.00001 3.27 3.00001C5.4375 3.00001 6.365 4.80626 6.2575 7.22875L7.5375 2.65876C7.85 1.59376 9.18749 0.285005 11.0337 0.705005C12.5462 1.11751 13.4737 2.67626 13.0737 4.20126L12.1275 8.00001L15.6712 2.30626C16.4587 0.943755 18.4112 0.490005 19.9487 1.37876C21.3112 2.16626 21.7862 4.25001 20.965 5.62751L16.3062 13.2538C19.8312 14.4263 22.4387 16.9063 23.4562 19.7963C24.1087 21.6563 22.5137 23.8813 20.5762 23.8813C18.1587 23.8813 17.7375 21.5813 16.2762 20.3238C15.4137 19.5738 14.2137 19.1338 12.9762 19.1713C10.5512 19.2588 8.45999 21.2 8.34749 23.6375C8.22249 26.3375 10.4112 28.7625 13.0737 28.7625C14.9487 28.7625 17.1112 27.3913 17.7712 25.6463C18.1937 24.53 19.5162 23.8788 20.7112 23.8788C22.6237 23.8788 24.0512 25.8513 23.4837 27.8038C21.9587 32.3663 17.5962 35.3163 12.5087 35.3038C6.27125 35.2663 0.476246 29.6988 0.476246 21.75Z"
                          fill="#FFC061"
                        />
                        <path
                          d="M11.4925 0.872498C12.7275 1.4475 13.4312 2.8375 13.0737 4.20125L12.195 7.72625L10.125 11.125L11.805 3.7225C11.9825 3.005 12.1187 1.58375 11.4925 0.872498ZM20.4587 1.77125C21.4175 2.725 21.6725 4.43875 20.965 5.6275L16.3125 13.2437C15.14 12.9025 13.8712 12.9025 12.45 12.9025C13.7575 12.6087 15.2412 12.2262 16.12 11.125C16.9987 10.0237 20.0275 4.7775 20.0275 4.7775C20.48 4.095 21.06 2.71375 20.4587 1.77125ZM6.265 6.995C6.29625 5.14375 5.69749 3.6875 4.37124 3.1875C4.87624 3.8075 5.01624 4.85875 5.00874 5.5L5.24249 11.125L6.265 6.995Z"
                          fill="#F8B649"
                        />
                      </svg>
                    </span>
                  )}
                </p>
              </div>
            </div>
            <p className="mb-2">{ticketData.feedback}</p>
          </div>
        ) : (
          <p>No feedback available</p>
        )}
      </CardBody>

      <Modal isOpen={ticketInfoModal} toggle={toggleTicketInfoModal} centered>
        <ModalHeader toggle={toggleTicketInfoModal} className="font-size-18">
          Edit Ticket info
        </ModalHeader>

        <ModalBody>
          {ticketData?.status?.category !== "solved" ? (
            <FormGroup>
              <Label for="priority" className="font-size-15">
                Priority <span className="text-danger">*</span>
              </Label>

              <Select
                id="priority"
                name="priority"
                options={[
                  { value: "Low", label: "Low" },
                  { value: "Normal", label: "Normal" },
                  { value: "High", label: "High" },
                  { value: "Urgent", label: "Urgent" },
                ]}
                // Pass the entire object for `value`, matching the selected option
                value={
                  formEditTicketInfo.priority
                    ? {
                        value: formEditTicketInfo.priority,
                        label: formEditTicketInfo.priority,
                      }
                    : null
                }
                onChange={selectedOption =>
                  setFormEditTicketInfo({
                    ...formEditTicketInfo,
                    priority: selectedOption.value,
                  })
                }
                styles={customStyles}
              />
            </FormGroup>
          ) : (
            ""
          )}

          <FormGroup>
            <Label for="statusSelect" className="font-size-15">
              Status <span className="text-danger">*</span>
            </Label>

            <Select
              id="statusSelect"
              name="statusSelect"
              options={statuses}
              value={statuses.find(
                option => option.value === formEditTicketInfo.statusSelect
              )}
              onChange={selectedOption =>
                setFormEditTicketInfo({
                  ...formEditTicketInfo,
                  statusSelect: selectedOption.value,
                })
              }
              styles={customStyles}
            />
          </FormGroup>

          {ticketData?.status?.category !== "solved" ? (
            <FormGroup>
              <Label for="category" className="font-size-15">
                Category <span className="text-danger">*</span>
              </Label>

              <Select
                id="category"
                name="category"
                options={categories}
                value={categories.find(
                  option => option.value === formEditTicketInfo.category
                )}
                onChange={selectedOption =>
                  setFormEditTicketInfo({
                    ...formEditTicketInfo,
                    category: selectedOption.value,
                  })
                }
                styles={customStyles}
              />
            </FormGroup>
          ) : (
            ""
          )}

          {userData?._id === ticketData?.creator?._id ||
          userData.role == "admin" ||
          isUserSupervisor(userData, teams, ticketData?.team?._id) ? (
            <>
              <FormGroup>
                <Label for="typeOfRequest">
                  Type of Request <span className="text-danger">*</span>
                </Label>
                <Select
                  id="typeOfRequest"
                  isClearable
                  options={[
                    { value: "RD0", label: "RD0" },
                    { value: "Edit RD0", label: "Edit RD0" },
                    { value: "Missing Data", label: "Missing Data" },
                    { value: "Design Review", label: "Design Review" },
                    { value: "RD6", label: "RD6" },
                    { value: "RD7", label: "RD7" },
                    { value: "Finance", label: "Finance" },
                    { value: "Inspection", label: "Inspection" },
                    { value: "MALATH Issue", label: "MALATH Issue" },
                    { value: "MALATH Complaint", label: "MALATH Complaint" },
                    { value: "Other", label: "Other" },
                  ]}
                  value={
                    formEditTicketInfo.requestOfType
                      ? {
                          value: formEditTicketInfo.requestOfType,
                          label: formEditTicketInfo.requestOfType,
                        }
                      : null
                  }
                  onChange={selectedOption => {
                    setFormEditTicketInfo({
                      ...formEditTicketInfo,
                      requestOfType: selectedOption.value,
                    })
                  }}
                  styles={customStyles}
                />
              </FormGroup>

              <FormGroup>
                <Label for="natureOfRequest" className="font-size-15">
                  Nature of Request <span className="text-danger">*</span>
                </Label>

                <Select
                  id="natureOfRequest"
                  name="natureOfRequest"
                  options={[
                    { value: "Request", label: "Request" },
                    { value: "Complaint", label: "Complaint" },
                    { value: "Inquiry", label: "Inquiry" },
                  ]}
                  value={
                    formEditTicketInfo.natureOfRequest
                      ? {
                          value: formEditTicketInfo.natureOfRequest,
                          label: formEditTicketInfo.natureOfRequest,
                        }
                      : null
                  }
                  onChange={selectedOption =>
                    setFormEditTicketInfo({
                      ...formEditTicketInfo,
                      natureOfRequest: selectedOption.value,
                    })
                  }
                  styles={customStyles}
                />
              </FormGroup>
            </>
          ) : (
            ""
          )}
        </ModalBody>

        <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
          <Button
            color="secondary"
            outline
            onClick={toggleTicketInfoModal}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Cancel
          </Button>

          <Button
            color="secondary"
            onClick={handleEditTicketInfoSubmit}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={userInfoModal} toggle={toggleUserInfoModal} centered>
        <ModalHeader toggle={toggleUserInfoModal}>Edit User info</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label className="font-size-15" for="teamSelect">
              Choose a team <span className="text-danger">*</span>
            </Label>

            <Select
              id="teamSelect"
              options={teams}
              value={teams.find(option => option.value === selectedTeamId)}
              onChange={handleTeamChange}
              styles={customStyles}
            />

            {showTeamError && <div className="text-danger mt-2">Required</div>}
          </FormGroup>

          <FormGroup>
            <Label className="font-size-15" for="assigneeSelect">
              Assignee
            </Label>

            <Select
              id="assigneeSelect"
              options={users}
              value={users.find(option => option.value === selectedUserId)}
              onChange={handleUserChange}
              isDisabled={!selectedTeamId} // Disable user selection until a team is selected
              styles={customStyles}
            />

            {!selectedUserId && requireTeamMember ? (
              <div className="text-danger mt-2">
                Please select a team member
              </div>
            ) : (
              ""
            )}
          </FormGroup>
        </ModalBody>

        <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
          <Button
            color="secondary"
            outline
            onClick={toggleUserInfoModal}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Cancel
          </Button>

          <SubmitButtonComponent
            disabledStatus={
              !selectedTeamId || (requireTeamMember && !selectedUserId)
            }
            statuses={statusesData}
            handleSubmit={handleEditUserInfoSubmit}
            handleSubmitStatus={handleEditUserInfoSubmit}
          />
        </ModalFooter>
      </Modal>

      <Modal isOpen={clientInfoModal} toggle={toggleClientInfoModal} centered>
        <ModalHeader toggle={toggleClientInfoModal}>
          Edit Client Details
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label className="font-size-15" for="clientName">
              Name <span className="text-danger">*</span>
            </Label>
            <Input
              id="clientName"
              name="name"
              type="text"
              defaultValue={formEditClientInfo.name}
              onChange={e =>
                setFormEditClientInfo({
                  ...formEditClientInfo,
                  name: e.target.value,
                })
              }
            />

            {clientInfoErrors.name && (
              <div className="text-danger">{clientInfoErrors.name}</div>
            )}
          </FormGroup>

          <FormGroup>
            <Label className="font-size-15" for="clientPhone">
              Phone Number <span className="text-danger">*</span>
            </Label>
            <Input
              id="clientPhone"
              name="number"
              type="text"
              placeholder="Phone Number"
              defaultValue={formEditClientInfo.number}
              onChange={e =>
                setFormEditClientInfo({
                  ...formEditClientInfo,
                  number: e.target.value,
                })
              }
            />

            {clientInfoErrors.number && (
              <div className="text-danger">{clientInfoErrors.number}</div>
            )}
          </FormGroup>

          <FormGroup>
            <Label className="font-size-15" for="clientEmail">
              Email <span className="text-danger">*</span>
            </Label>
            <Input
              id="clientEmail"
              name="email"
              type="email"
              placeholder="Email Address"
              defaultValue={formEditClientInfo.email}
              onChange={e =>
                setFormEditClientInfo({
                  ...formEditClientInfo,
                  email: e.target.value,
                })
              }
            />

            {clientInfoErrors.email && (
              <div className="text-danger">{clientInfoErrors.email}</div>
            )}
          </FormGroup>
        </ModalBody>

        <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
          <Button
            color="secondary"
            outline
            onClick={toggleClientInfoModal}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Cancel
          </Button>

          <Button
            color="secondary"
            onClick={handleEditClientInfoSubmit}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  )
}

export default EditTicketInfo
