import React, { useEffect, useState } from "react"
import { GoBackSingleTemplateIcon } from "components/Common/Icons/Chat/Conversation/ConversationIcons"
import { Formik, Field, ErrorMessage, FieldArray } from "formik"
import { Form, Input, Spinner } from "reactstrap"
import axios from "axios"
import configs from "config"
import { useSelector, useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import { unAuthUser } from "store/actions"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const { Url, apiVersion } = configs.client

function TemplateDataPreview({
  chatId,
  selectedTemplateFile,
  setSelectedTemplateFile,
  headerVar,
  bodyVars,
  singleTemplateData,
  setSingleTemplateData,
  setShowTemplates,
  setSendingTemplateLoading,
  sendingTemplateLoading,
}) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [localTemplateFile, setLocalTemplateFile] = useState(null)

  useEffect(() => {
    setLocalTemplateFile(selectedTemplateFile)
  }, [selectedTemplateFile])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="d-flex flex-column gap-4 show-templates">
      <ToastContainer />

      <span
        onClick={() => {
          setSingleTemplateData(null)
        }}
        className="go-back"
      >
        <GoBackSingleTemplateIcon />
      </span>

      <div className="d-flex flex-column gap-2 templates-container">
        {/* single template header */}
        {singleTemplateData.components.map((component, idx) => {
          if (component.example && component.example.header_text) {
            headerVar = component.example.header_text[0]
          }

          if (component.type == "HEADER")
            return (
              <div className="" key={component.text}>
                <h5
                  dir={singleTemplateData.language == "ar" ? "rtl" : "ltr"}
                  className="template-header"
                >
                  {component.text}
                </h5>
              </div>
            )
        })}

        <Formik
          initialValues={{
            headerVarsValue: "",
            bodyVarsValues: [],
            file: "",
          }}
          onSubmit={async (values, actions) => {
            const formData = new FormData()
            // setDisableSendTemplateBtn(true)
            // Append the form values to the FormData object
            formData.append("templateName", singleTemplateData.name)

            if (values.headerVarsValue) {
              formData.append(headerVar, values.headerVarsValue)
            }

            if (values.bodyVarsValues) {
              values.bodyVarsValues.forEach((value, index) => {
                formData.append(bodyVars[index], value)
              })
            }

            {
              singleTemplateData?.components.map((component, index) => {
                if (component.format === "IMAGE") {
                  actions.setValues({
                    ...values,
                    file: localTemplateFile,
                  })

                  formData.append("file", localTemplateFile)
                }
              })
            }

            {
              singleTemplateData?.components.map((component, index) => {
                if (component.format === "DOCUMENT") {
                  actions.setValues({
                    ...values,
                    file: localTemplateFile,
                  })

                  formData.append("file", localTemplateFile)
                }
              })
            }

            function formDataToObject(formData) {
              const object = {}
              formData.forEach((value, key) => {
                if (object.hasOwnProperty(key)) {
                  if (!Array.isArray(object[key])) {
                    object[key] = [object[key]]
                  }

                  object[key].push(value)
                } else {
                  object[key] = value
                }
              })
              return object
            }

            {
              singleTemplateData.components.map((component, idx) => {
                if (component.example && component.example.header_text) {
                  headerVar = component.example.header_text[0]
                }

                if (component.type == "HEADER")
                  return (
                    <div className="" key={component.text}>
                      <h5>{component.text}</h5>
                    </div>
                  )
              })
            }

            const formDataObject = formDataToObject(formData)

            setSendingTemplateLoading(true)
            axios
              .post(
                `${Url}/${apiVersion}/chats/${chatId}/messages/templates`,
                formDataObject,
                {
                  headers: {
                    Authorization: `Bearer ${access}`,
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(res => {
                setSingleTemplateData(null)
                setShowTemplates(false)
                setSendingTemplateLoading(false)

                toast.success("Template sent successfully!")
              })
              .catch(err => {
                if (
                  err.response.status == 401 ||
                  err.response.status == 0 ||
                  !err.response.status
                ) {
                  dispatch(unAuthUser())
                } else {
                  toast.error("Failed to send the template. Please try again.")

                  setSingleTemplateData(null)
                  setShowTemplates(false)
                  setSendingTemplateLoading(false)
                  console.log(err)
                }
              })
          }}
        >
          {formik => (
            <Form
              key="formikSingleTemplatePreviewForm"
              dir={singleTemplateData.language == "ar" ? "rtl" : "ltr"}
              onSubmit={formik.handleSubmit}
            >
              {headerVar && (
                <div className="">
                  <div className="input-group input-group-sm my-1">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      {"{{1}}"}
                    </span>

                    <Field
                      type="text"
                      required
                      autoComplete="off"
                      className={`form-control ${
                        formik.errors.headerVarsValue &&
                        formik.touched.headerVarsValue
                          ? "is-invalid"
                          : ""
                      }`}
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-sm"
                      name="headerVarsValue"
                    />
                  </div>

                  <ErrorMessage
                    name="headerVarsValue"
                    component="div"
                    className="text-danger"
                  />
                </div>
              )}

              <div className="">
                <p
                  style={{
                    margin: "0px",
                  }}
                >
                  {singleTemplateData.components.map(component => {
                    if (component.example && component.example.body_text) {
                      bodyVars = component.example.body_text[0]
                    }

                    if (component.type == "BODY")
                      return component.text
                        .split("\n")
                        .map((str, idx) => <p key={idx}>{str}</p>)
                  })}

                  {singleTemplateData?.components.map((component, idx) => {
                    if (
                      component.format === "DOCUMENT" ||
                      component.format === "IMAGE"
                    )
                      return (
                        <div key={idx} className="py-1">
                          <div className="input-group input-group-sm my-1">
                            <span
                              className="input-group-text"
                              id="inputGroup-sizing-sm"
                            >
                              {component.format === "DOCUMENT" ? "doc" : "img"}
                            </span>

                            <Input
                              type="file"
                              required
                              accept={
                                component.format === "DOCUMENT"
                                  ? ".doc, .pdf, .csv, .xsls"
                                  : "image/*"
                              }
                              className={`form-control ${
                                formik.errors.file && formik.touched.file
                                  ? "is-invalid"
                                  : ""
                              }`}
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-sm"
                              name="file"
                              onChange={event => {
                                // event.preventDefault()

                                formik.setFieldValue(
                                  "file",
                                  event.currentTarget.files[0]
                                )

                                setSelectedTemplateFile(event.target.files[0])
                              }}
                            />
                          </div>

                          <ErrorMessage
                            name="file"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      )
                  })}

                  {bodyVars && (
                    <FieldArray name="bodyVarsValues">
                      {({ remove, push }) => (
                        <div>
                          {bodyVars.map((bodyVarInput, idx) => (
                            <div key={idx} className="py-1">
                              <div className="input-group input-group-sm my-1">
                                <span
                                  className="input-group-text"
                                  id="inputGroup-sizing-sm"
                                >
                                  {`{{${idx + 1}}}`}
                                </span>

                                <Field
                                  type="text"
                                  required
                                  autoComplete="off"
                                  className={`form-control ${
                                    formik.errors.bodyVarsValues &&
                                    formik.errors.bodyVarsValues[idx] &&
                                    formik.touched.bodyVarsValues &&
                                    formik.touched.bodyVarsValues[idx]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  aria-label="Sizing example input"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name={`bodyVarsValues.${idx}`}
                                />
                              </div>

                              <ErrorMessage
                                name={`bodyVarsValues.${idx}`}
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  )}
                </p>

                {singleTemplateData.components.map((component, idx) => {
                  if (component.type == "FOOTER")
                    return (
                      <div key={component.text}>
                        <p className="text-dark">{component.text}</p>
                      </div>
                    )
                })}

                {singleTemplateData.components.map((component, idx) => {
                  if (component.type == "BUTTONS")
                    return component.buttons.map((button, idx) => {
                      return (
                        <button
                          className="btn btn-outline-primary d-block mb-1"
                          key={idx}
                        >
                          {button.text}
                        </button>
                      )
                    })
                })}

                <div className="button-send-template">
                  <button
                    disabled={sendingTemplateLoading}
                    type="submit"
                    className="btn btn-secondary mt-2"
                  >
                    {sendingTemplateLoading
                      ? "Sending Template..."
                      : `Send Template`}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default TemplateDataPreview
