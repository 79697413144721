import React, { useEffect, useState } from "react"
import { Formik, useFormik, validateYupSchema } from "formik"
import { FormControl } from "react-bootstrap"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Toast,
  ToastBody,
} from "reactstrap"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import Select from "react-select"
import Switch from "react-switch"
import { useDispatch } from "react-redux"
import { unAuthUser } from "store/actions"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function EditCategories({
  toggle,
  isOpen,
  setShowEditToast,
  fetchAllCategories,
  Category,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const dispatch = useDispatch()

  const AddCategoriesSchema = Yup.object().shape({
    name: Yup.string()
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    description: Yup.string(),
  })

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))
  useEffect(() => {
    if (Category) {
      setFieldValue("name", Category?.name)
      setFieldValue("description", Category?.description)
    }
  }, [Category])

  // formik
  const {
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      name: Category?.name || "",
      description: Category?.description || "",
      status: Category?.status || "",
    },
    validationSchema: AddCategoriesSchema,
    onSubmit: values => {
      setIsLoading(true)

      axios
        .patch(
          `${Url}/${apiVersion}/ticketCategories/${Category?._id}`,
          values,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              Authorization: `Bearer ${access}`,
            },
          }
        )
        .then(res => {
          // Assume status code 200 is the success status code
          if (res.status === 200 || res.status === 204) {
            fetchAllCategories()
            toggle()
            // toggle();
            setIsLoading(false)

            toast.success("Category edited successfully!")
          } else {
            console.error("Unexpected status code:", res.status, res.data)
            setIsLoading(false)
          }
        })
        .catch(error => {
          setIsLoading(false)

          if (
            error?.response?.status === 401 ||
            error?.response?.status === 0 ||
            !error.response?.status
          ) {
            dispatch(unAuthUser())
          } else {
            toast.error("An error occurred. Please try again later.")
          }
        })
    },
  })

  // toggle switch "status"
  const [isChecked, setIsChecked] = useState(Category?.status === "active")
  useEffect(() => {
    if (Category?.status) {
      setIsChecked(Category.status === "active")
    }
  }, [Category])
  useEffect(() => {
    setFieldValue("status", isChecked ? "active" : "inactive")
  }, [isChecked, setFieldValue])
  const toggleSwitch = () => {
    setIsChecked(!isChecked)
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      // onClosed={handleModalClosed}
      size="lg"
      centered
    >
      <ToastContainer />

      <ModalHeader toggle={toggle}>Edit Category</ModalHeader>
      <ModalBody>
        {errorMessage && (
          <div className="" aria-live="polite" aria-atomic="true">
            <div className="">
              <Toast isOpen={errorMessage} fade={true}>
                <ToastBody className="delete-message-bg">
                  <h5 className="m-0 mb-2">Error</h5>
                  <p className="m-0">{errorMessage}</p>
                </ToastBody>
              </Toast>
            </div>
          </div>
        )}

        <Row className="justify-content-center mt-4">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup className="m-0 m-mb-3">
                  <Label className="mb-1">Name</Label>
                  <Input
                    className=" p-5px m-0 "
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                  />
                  {errors.name &&
                    touched.name &&
                    values?.name?.trim() === "" && (
                      <div className="error mb-0">{errors.name}</div>
                    )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="m-0 m-mb-5">
                  <Label className="mb-1">Description</Label>
                  <FormControl
                    as="textarea"
                    rows={6}
                    id="description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="p-2"
                  />
                  {errors.description && touched.description && (
                    <div className="error mb-0">{errors.description}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row className="d-flex ">
              <FormGroup className="m-0 m-mb-5 d-flex  align-items-center justify-content-between">
                {/* <Label className="mb-3">Status</Label> */}
                <div className="custom-switch d-flex  align-items-center justify-content-center  ">
                  <Label className="custom-checkbox-text w-sm   fs-5">
                    {isChecked ? "Active" : "Inactive"}
                  </Label>
                  <Switch
                    name="status"
                    onChange={toggleSwitch}
                    checked={isChecked}
                    onColor="#86d3ff"
                    offColor="#ccc"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={48}
                  />
                  {errors.status && touched.status && (
                    <div className="error mb-0">{errors.status}</div>
                  )}
                </div>
              </FormGroup>
            </Row>

            {isLoading && (
              <div className="loading-overlay">
                <Spinner />
              </div>
            )}
            <FormGroup className=" d-flex justify-content-end gap-3 edit-popup">
              <Button type="submit" className="edit-button">
                Edit
              </Button>{" "}
              <Button onClick={toggle} className="edit-button cancel-delete ">
                Cancel
              </Button>
            </FormGroup>
          </form>
        </Row>
      </ModalBody>
    </Modal>
  )
}
